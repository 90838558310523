.notification-img {
    height: 31px;
    width: 31px;
    border-radius: 37px;
    margin-right: 10px;
}

.n-title {
    font-weight: 600;
}

.n-created-at {
    font-size: 11px;
}

.n-message {
    display: block;
}

.n-message-link {
    text-decoration: none !important;
    color: black !important;
}

@media screen and (max-width: 1024px) {
    .n-title {
        font-size: 12px;
    }

    .n-created-at {
        font-size: 10px;
    }

    .n-message {
        font-size: 11px;
    }
}
