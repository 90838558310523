.device-for-event-dd {
    position: relative;
    background-color: #3b3b3b;
    min-height: 300px;
}

.channel-mappings {
    display: inline-block;
    margin-right: 15px;
    width: calc((100% / 4) - 15px);
}

.mapping-type-selection {
    display: block;
    margin-top: 10px;
}

.mapping-type-selection-input {
    margin: 5px !important;
    vertical-align: middle !important;
}

.device-for-event-dd .css-wqgs6e,
.device-for-event-dd .css-v73v8k,
.device-for-event-dd .css-z5z6cw {
    cursor: pointer !important;
}

.event-device-drop-down-separator {
    position: absolute;
    height: 100%;
    width: 3px;
    margin: 0;
    background-color: white;
    display: inline-block;
}

.device-information-container {
    width: calc(100% / 4);
    display: inline-block;
    vertical-align: top;
    margin-bottom: 40px;
    padding: 10px;
}

.device-information {
    padding: 3px;
    background-color: rgb(228, 228, 228);
    background-size: cover;
}

.device-information-text {
    padding: 25px;
    background-color: rgba(37, 37, 37, 0.85);
    color: white;
    font-size: 20px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
}

.device-info-label {
    display: block;
}

.channel-mapping {
    color: white;
    display: inline-block;
    vertical-align: top;
    padding: 10px 20px;
    width: calc((100% / 3) * 3 - 3px);
}

.channel-mapping-with-device-info {
    width: calc((100% / 4) * 3 - 3px);
}

.mapping-destinations,
.mapping-sources {
    width: calc(60% - 30px);
    display: inline-block;
    vertical-align: top;
}

.mapping-sources {
    width: 20%;
    margin-right: 15px;
}

.mapping-destinations {
    text-align: right;
}

.mapping-track-destinations {
    float: right;
    width: 25%;
}

.channel-output-btn-container {
    text-align: right;
    width: 100%;
}

.channel-output-btn,
.input-channel-btn,
.channel-output-chan-btn {
    width: 45px;
    height: 45px;
    border-radius: 17.5px;
    display: block;
    margin: 5px 0;
    outline: none !important;
    border: none;
    cursor: pointer;
    background-color: #fff;
    color: #333;
    padding: 12px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.input-channel-btn {
    margin: 10px 0;
    display: inline-block;
    margin-right: 5px;
}

.channel-output-btn,
.channel-output-chan-btn {
    display: inline-block;
}

.channel-output-chan-btn {
    width: 35px;
    height: 35px;
    padding: 7px 12px;
    margin-right: 5px;
}

.channel-output-btn:hover,
.input-channel-btn:hover,
.channel-output-btn-selected:focus,
.input-channel-btn-selected:focus,
.channel-output-btn-selected,
.input-channel-btn-selected {
    border: 3px solid black;
    padding: 9px 12px;
}

.channel-output-chan-btn-all-channels {
    width: 200px;
}

.mapped-channel {
    position: relative;
    display: inline-block;
}

.remove-input-track-icon,
.remove-track-icon,
.remove-channel-icon {
    position: absolute;
    top: 1px;
    right: 2px;
    cursor: pointer;
    border-radius: 15px;
    width: 17px;
}

.remove-track-icon {
    top: -5px;
    left: -7px;
}

.remove-input-track-icon {
    top: -5px;
    right: -7px;
}

#mapping-label-destinations,
#mapping-label-sources {
    display: inline-block;
    width: 50%;
    margin: 0px 0px 10px 0px;
    font-size: 18px;
}

#mapping-label-instructions {
    left: 265px;
    top: 20px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
}

.no-teams-invited {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
}

#mapping-label-destinations {
    text-align: right;
}

.team-outline {
    border: 1px dotted grey;
    padding: 10px;
    padding-bottom: 5px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    position: relative;
}

.team-outline-label {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

#output-track-radio-select {
    margin-left: 10px;
}

.label-align-left {
    position: absolute;
    left: 10px;
}

.output-label {
    margin-top: 10px;
}

.mapping-label-container {
    margin-bottom: 10px;
}

.add-output-icon,
.add-track-icon,
.add-input-icon {
    margin-right: 10px;
    margin-top: -7px;
    border: 1px solid white;
    border-radius: 15px;
    height: 25px;
    width: 25px;
    text-align: center;
    padding-top: 4.5px;
    cursor: pointer;
    padding-left: 1px;
}

.add-output-icon {
    left: 15px;
}

.add-track-icon {
    position: absolute;
    right: -1px;
    top: 15px;
}

.input-track-label {
    display: block;
}

.radio-add-input-track {
    margin-right: 10px !important;
}

#add-input-track-selector-value,
#add-output-track-pid {
    width: 95%;
    margin-left: 1%;
}

#hex-prefix-span {
    width: 4%;
}

.input-track-label-name {
    vertical-align: middle;
}

.no-input-track-label,
.input-track-label-name,
.input-track-label-channels {
    display: inline-block;
    width: 85%;
}

.no-input-track-label {
    margin-top: 5px;
    display: block;
}

.input-track-label-channels {
    margin-right: 15px;
    font-size: 14px;
}

.input-track-show-channel-glpyh {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    vertical-align: text-top;
}

.add-input-selector {
    display: block;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 15px;
    margin-right: 10px;
}

.add-input-selector {
    margin: 0;
    vertical-align: text-bottom;
}

.add-input-selector .css-15k3avv {
    height: unset !important;
    overflow-y: unset !important;
    margin-top: -5px;
}

.add-input-exit-icon {
    float: right;
}

.add-input-modal-body {
    width: 66%;
    margin: auto;
}

.add-input-input {
    width: 100%;
    background-color: #efefef;
    border-radius: 5px;
    margin: 5px 0;
    height: 35px;
    border: 1px solid gray;
    padding: 0 10px;
}

.add-input-label {
    font-weight: bold;
}

.add-input-radio,
.add-input-radio-label {
    width: 25%;
    display: inline-block;
    margin-top: 5px;
}
