.spalk-btn-styling {
    background: linear-gradient(to right, #0691ee, #43aef0);
    border: 3px solid #4494de;
    border-radius: 30px;
    color: white;
}

.spalk-btn-styling:hover {
    border: 3px solid #2181db;
    color: white;
}

.spalk-btn-styling:active,
.spalk-btn-styling:focus {
    outline: none !important;
    color: white;
    border: 3px solid #4494de;
}

.ReactTable.dashboard-react-table .rt-td {
    overflow: initial;
    height: 116px !important;
}
