.team-chat-toast-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    padding-top: 20px;
    padding-right: 20px;
    pointer-events: none;
}

.team-chat-toast-container.team-chat-toast-container-redundant-connection-shift {
    padding-top: 32px;
}

.team-chat-toast {
    padding-bottom: 10px;
    max-width: 100%;
}

.team-chat-toast-inner {
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 13px;
    padding: 5px 0 5px 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 6px #00000042;
    position: relative;
    user-select: none;
}

.team-chat-toast-inner p {
    @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-base;
    margin: 0;
    margin: 0;
    margin-left: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.team-chat-toast-inner .glyphicon {
    padding: 15px;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity ease 0.1s;
}

.team-chat-toast-inner .glyphicon:hover {
    opacity: 1;
}

:root {
    --team-chat-toast-enter-in: cubic-bezier(0.16, 1, 0.3, 1) 800ms; /* easeOutExpo */
    --team-chat-toast-enter-shift: cubic-bezier(0.16, 1, 0.3, 1) 800ms; /* easeOutExpo */
    --team-chat-toast-enter-out: cubic-bezier(0.16, 1, 0.3, 1) 400ms; /* easeOutExpo */
}

.team-chat-toast-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.team-chat-toast-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity var(--team-chat-toast-enter-in), transform var(--team-chat-toast-enter-in);
    transition-delay: 150ms;
}

.team-chat-toast-exit .team-chat-toast-inner {
    transform: none;
    opacity: 1;
}

.team-chat-toast-exit-active .team-chat-toast-inner {
    opacity: 0;
    transition: opacity var(--team-chat-toast-enter-out), transform var(--team-chat-toast-enter-out);
}

.team-chat-toast-enter-done {
    transform: none;
}

.team-chat-toast-container-shift-up .team-chat-toast-enter-done,
.team-chat-toast-container-shift-up .team-chat-toast-exit {
    animation: team-chat-toast-shift-up var(--team-chat-toast-enter-shift);
    animation-fill-mode: both;
}

.team-chat-toast-container-shift-up .team-chat-toast-enter-done {
    animation-delay: 50ms;
}

@keyframes team-chat-toast-shift-up {
    from {
        transform: none;
    }

    to {
        transform: translateY(-100%);
    }
}

.team-chat-toast-container-shift-down .team-chat-toast-enter-done,
.team-chat-toast-container-shift-down .team-chat-toast-exit {
    animation: team-chat-toast-shift-down var(--team-chat-toast-enter-shift);
    animation-fill-mode: both;
}

.team-chat-toast-container-shift-down .team-chat-toast-enter-done {
    animation-delay: 50ms;
}

@keyframes team-chat-toast-shift-down {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: none;
    }
}
