.error-msg {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-content: center;
    padding-top: 150px;
    max-width: 600px;
}

.padding-bottom-170px {
    padding-bottom: 170px;
}

.error-msg-inner {
    width: 100%;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 10px;
}

.message {
    color: #000333;
    margin-left: auto;
    margin-right: auto;
}

.link-to-home {
    color: #9e9e9e;
}

.link-to-home:hover {
    text-decoration: none;
    color: #000333;
    transition: color 0.7s ease;
}

.error-tooltip > .tooltip-inner {
    background-color: red;
    color: white;
}

.error-top-placement > .tooltip-arrow {
    border-top-color: red !important;
}

.error-left-placement > .tooltip-arrow {
    border-left-color: red !important;
}

.error-right-placement > .tooltip-arrow {
    border-right-color: red !important;
}

.error-bottom-placement > .tooltip-arrow {
    border-bottom-color: red !important;
}

#error-tooltip {
    z-index: 1;
}
@media (max-width: 768px) {
    .box-sizing {
        font-size: 10px;
        line-height: 1.2;
        display: inline-block;
    }
}
