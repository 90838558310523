.profile-wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    border: 0.1px none;
    padding: 0 10px 0 20px;
}
.personal-info-wrapper {
    box-shadow: 0 5px 18px -5px #323231;
}

.profile-personal {
    background-color: white;
    padding: 10px 10px 10px 20px;
    margin: 0;
    border-bottom: 1px solid lightgray;
    overflow: hidden;
}

.profile-mobile-upcoming-container {
    margin-top: 50px;
}

.profile-personal-title {
    display: inline-block;
    font-size: 18px;
}
.profile-personal-edit {
    display: inline-block;
    float: right;
    font-size: 17px;
    color: #323231;
    margin-right: -3px;
}

.profile-event-upload-icon {
    position: absolute;
    margin-top: 63px;
    right: 20px;
    font-size: 17px;
    color: #323231;
}

.edit-video-text {
    font-family: "Nunito Sans", sans-serif;
    display: inline-block;
    font-size: 17px;
    color: #323231;
    position: absolute;
    right: -38px;
}

.comm-edit-glyph {
    position: absolute;
    right: 60px;
    top: 10px;
}

.profile-personal-text {
    display: block;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #323231 !important;
    padding-right: 20px;
}

.profile-personal-text:hover,
.profile-personal-text:focus {
    text-decoration: none !important;
}

.profile-personal-social {
    display: inline-block;
    background-size: 100%;
    width: 14%;
    max-width: 46px;
    height: 46px;
    vertical-align: top;
    margin: 1%;
    cursor: pointer;
    background-repeat: no-repeat;
}

.profile-personal-social-link {
    width: 100%;
}

.loggedin-true:hover {
    background-image: url("assets/images/AddSM.png") !important;
}

.commentary-title {
    margin-left: 0px;
    text-align: left;
    overflow: hidden;
    column-width: 400px;
    word-wrap: break-word;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin-bottom: 25px;
}
.comm-link-container {
    display: inline-block;
}

#date-id {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

#empty-message {
    display: inline-block;
    font-size: 13px;
}

.container {
    width: 100%;
    margin: 0 auto;
    height: 170px;
}
.upload-item {
    background-color: #fff;
    margin-bottom: 10px !important;
    padding: 20px 0 10px 20px;
    height: auto;
    margin-top: 0;
}

.profile-tile-title {
    margin-bottom: 10px;
    display: inline-block;
    color: #333;
    margin-left: 1%;
}

.upload-new-button {
    float: right;
    position: relative;
    top: -30px;
    left: -11px;
}

.tutorial-link {
    color: #9e9e9e;
}

.tutorial-link:hover {
    text-decoration: none;
    color: #000;
}

.commentary-link {
    color: #000;
}

.commentary-link:hover {
    cursor: pointer;
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.component-title {
    font-size: 18px;
    line-height: 12px;
    letter-spacing: 0.7px;
    font-family: "Nunito Sans";
    margin-bottom: 22px;
    margin-right: 3px;
    display: block;
    padding-left: 10px;
}

.component-outer {
    width: 93%;
    padding: 6px 0;
    min-height: 330px;
    position: relative;
}

.event-tile-img-empty {
    margin-top: 50px;
    height: 100px;
}

.mobile-profile-menu {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
}

.mobile-tabs {
    height: 33px;
    width: 33.33333333%;
    background-color: #d7d7d7;
    display: inline-block;
    text-align: center;
    padding-top: 7px;
    cursor: pointer;
    color: #555555;
    font-size: 13px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 10px;
}

.mobile-tabs-mini {
    height: 33px;
    width: 50%;
    background-color: white;
    display: inline-block;
    text-align: center;
    padding-top: 7px;
    cursor: pointer;
    color: #b1afaf;
    font-size: 13px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.mini-tab-true {
    color: #131313;
}

.tab-true {
    background-color: #464646;
    color: white;
}

.profile-personal-title-div {
    display: block;
    margin-bottom: 10px;
    font-family: "Nunito Sans", sans-serif;
    color: #323231;
    line-height: 1;
}

.personal-profile-social-div {
    display: block;
}

.tile-text-prop {
    font-family: "Nunito Sans", sans-serif;
}

.tile-col-outer {
    padding: 0;
    text-align: center;
    margin-bottom: 15px;
    float: none;
    display: inline-block;
}

.upcoming-events-mobile-functions {
    display: inline-block;
    width: 36%;
    vertical-align: top;
    text-align: right;
}

.no-border {
    border: unset !important;
}

.header-cover-div {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.header-cover-gradient {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.header-cover-image {
    width: 100%;
    min-height: 40vw;
    max-height: 40vw;
}

.header-cover-profile-pic {
    position: absolute;
    z-index: 2;
    bottom: 11px;
    left: 3%;
    height: 10vw;
    width: 10%;
    min-width: 72px;
    border-radius: 30px;
    object-fit: cover;
}

.header-cover-username {
    position: absolute;
    z-index: 2;
    font-family: "Nunito Sans", sans-serif;
    font-size: 4vw;
    font-weight: bold;
    color: #ffffff;
    bottom: 8%;
    left: 15%;
}

.white-error {
    float: none !important;
    color: white !important;
    position: absolute;
    bottom: 9%;
    z-index: 2;
    left: 15%;
}

.cover-button,
.profile-button {
    position: absolute;
    width: 0;
    height: 0;
    font-size: 0;
    visibility: hidden;
    padding: 1vw 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 2vw;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.profile-button {
    padding: 1vw 12px;
    line-height: 1vw;
}

.header-cover-div:hover > .cover-button,
.cover-button:hover,
.cover-button:focus {
    visibility: visible;
    background: rgba(255, 255, 255, 0.65);
    color: black;
    position: absolute;
    top: 17vw;
    width: 20%;
    left: 40%;
    height: 4vw;
    font-size: 1.6vw;
    cursor: pointer;
    z-index: 10;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    border-radius: 50px;
    border: 0px solid transparent;
    outline: none;
}

.header-cover-div:hover .profile-button,
.profile-button:hover,
.profile-button:focus {
    visibility: visible;
    background: rgba(255, 255, 255, 0.65);
    color: black;
    position: absolute;
    bottom: 11px;
    width: 10%;
    left: 3%;
    height: 3vw;
    font-size: 1vw;
    cursor: pointer;
    z-index: 10;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    border-radius: 0px 0px 30px 30px;
    border: 0px solid transparent;
    outline: none;
}

.set-new-cover-image {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    top: 17vw;
    width: 20%;
    left: 40%;
    height: 4vw;
    z-index: 11;
    border-radius: 50px;
    text-align: center;
}

.set-new-profile-image {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    bottom: 11px;
    width: 10%;
    left: 3%;
    height: 35px;
    z-index: 11;
    border-radius: 0px 0px 30px 30px;
    text-align: center;
}

.confirm-imgs-cover {
    height: 80%;
    margin-top: 2.5%;
    margin-right: 4.5%;
    margin-left: 4.5%;
    cursor: pointer;
}

.confirm-imgs-profile {
    height: 80%;
    margin-top: 2.5%;
    margin-right: 4.5%;
    margin-left: 4.5%;
    cursor: pointer;
}

#image-for-active-Snapchat {
    cursor: unset;
}

.empty-tile-text {
    font-family: "Nunito Sans", sans-serif;
}

.upcoming-game-horizontal-padding {
    padding-left: 20px;
    padding-right: 10px;
}

@media screen and (min-width: 1440px) {
    .header-cover-image {
        min-height: 35vw;
        max-height: 35vw;
    }
}

@media screen and (max-width: 1024px) {
    .profile-wrapper {
        padding: 0 10px;
    }
    .component-title {
        font-size: 15px;
        padding-left: 5px;
    }

    .profile-personal-title {
        vertical-align: super;
        font-size: 15px;
    }

    .profile-personal {
        padding: 10px;
    }

    #events-component-mobile {
        padding: 0px;
        margin-top: -60px;
    }

    .upcoming-game-horizontal-padding {
        padding: 0px 10px;
    }

    .profile-personal-edit {
        font-size: 14px;
    }

    .profile-personal-text {
        font-size: 1.5vw;
    }

    .profile-personal-social {
        height: 39px;
    }

    .empty-tile-text {
        font-size: 12px;
    }

    .header-cover-div:hover .profile-button,
    .profile-button:hover,
    .profile-button:focus {
        height: 25px;
        font-size: 11px;
        border-radius: 0 0 18px 18px;
    }

    .set-new-profile-image {
        height: 30px;
        z-index: 11;
        border-radius: 0 0 18px 18px;
    }

    .header-cover-profile-pic {
        border-radius: 18px;
    }
}

@media screen and (max-width: 860px) {
    .profile-personal-social {
        height: 30px;
    }

    .profile-personal-title {
        vertical-align: super;
        font-size: 13.5px;
    }

    .component-outer {
        width: 95%;
    }

    .edit-video-text {
        right: -35px;
    }
}

@media screen and (max-width: 768px) {
    .profile-wrapper {
        padding: 0;
    }
    .upload-item-col {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .mobile-profile-menu {
        width: 100%;
        height: 33px;
    }

    .profile-personal {
        border-bottom: 1px solid rgba(50, 50, 49, 0.05);
    }

    .profile-personal-title-div {
        margin-bottom: 0;
    }

    .profile-personal-title {
        font-size: 14px;
    }

    .profile-personal-text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
    }

    .profile-personal-social {
        width: 20%;
        max-width: 48px;
        height: 48px;
    }

    .personal-profile-social-div {
        text-align: center;
    }

    .personal-info-wrapper {
        box-shadow: unset;
    }

    .component-outer {
        width: 100%;
    }

    .tile-col-outer {
        border-bottom: 1px solid rgba(50, 50, 49, 0.05);
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0;
        display: block;
    }

    .component-title {
        padding-left: 8px;
    }

    .header-cover-div {
        margin-bottom: 0;
    }

    .header-cover-image {
        min-height: 50vw;
        max-height: 300px;
    }

    .header-cover-profile-pic {
        left: 15px;
        min-height: 72px;
        border-radius: 12px;
    }

    .header-cover-username {
        left: 93px;
        bottom: 30px;
    }

    .white-error {
        left: 15px;
        bottom: 0;
    }

    .header-cover-div:hover .profile-button,
    .profile-button:hover,
    .profile-button:focus {
        width: 10%;
        min-width: 72px;
        left: 15px;
        bottom: 11px;
        border-radius: 0 0 12px 12px;
    }

    .header-cover-div:hover > .cover-button,
    .cover-button:hover,
    .cover-button:focus {
        background: rgba(255, 255, 255, 0.9);
        top: 17vw;
        width: 35%;
        min-width: 150px;
        left: 30%;
        height: 30px;
        font-size: 13px;
    }

    .set-new-profile-image {
        left: 15px;
        min-width: 72px;
        height: 25px;
        border-radius: 0 0 12px 12px;
    }

    .set-new-cover-image {
        height: 30px;
    }

    .confirm-imgs-cover {
        height: 24px;
        margin-top: 3px;
    }
}

@media (max-width: 425px) {
    .mobile-font-size {
        font-size: 3.5vw;
    }
    #events-component-mobile {
        margin-top: 0px;
    }

    .profile-event-upload-icon {
        margin-top: 13px;
    }

    .profile-mobile-upcoming-container {
        margin-top: -5px;
    }

    .mobile-padding {
        padding-left: 0px;
    }

    .header-cover-username {
        font-size: 16px;
        bottom: 30px;
    }

    .header-cover-div:hover > .cover-button,
    .cover-button:hover,
    .cover-button:focus {
        top: 10px;
        left: unset;
        right: 10px;
    }

    .set-new-cover-image {
        top: 10px;
        left: unset;
        right: 10px;
    }
}

.no-horizontal-padding {
    padding-left: 0px;
    padding-right: 0px;
}
