.upcoming-container {
    font-family: "Nunito Sans", sans-serif;
    padding: 10px 60px;
}

#ue-banner {
    font-weight: 800;
}

.nunito-font {
    font-family: "Nunito Sans", sans-serif;
}
.ue-white-background {
    background-color: #ffffff;
    box-shadow: 0px 8px 13px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-bottom: 10px;
}

.display-inline-block {
    display: inline-block;
}

.no-event-font-styling {
    text-align: center;
    width: 100%;
    font-size: 18px;
}
.tile-sports-image {
    height: 50px;
    widows: 50px;
}
.information-holder {
    vertical-align: middle;
}

.information-row {
    padding-bottom: 15px;
}

.information-holder {
    width: 65%;
    padding-right: 15px;
}

.float-right {
    float: right;
}

.align-right {
    text-align: right;
}

.game-description {
    padding-bottom: 10px;
    word-break: break-word;
}

.flex-display {
    display: flex;
}

.max-width {
    width: 100%;
}

.general-padding {
    padding: 10px;
}

.general-horizontal-padding {
    padding-left: 10px;
    padding-right: 10px;
}

.general-vertical-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.no-padding {
    padding: 0px;
}

.no-select-highlight {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.center-flex {
    display: flex;
    align-items: center;
}

.text-padding {
    padding-left: 5px;
}

.relative-positioning {
    position: relative;
}

.absolute-positioning {
    position: absolute;
}

.flex-align-right {
    margin-left: auto;
}

.blue-text {
    color: blue;
}

.gif-holder {
    padding-left: 25%;
}

.gif-styling {
    width: 100%;
}
@media (max-width: 768px) {
    .ue-white-background {
        box-shadow: unset;
    }
    .upcoming-container {
        margin-top: 5px;
        padding: 10px;
    }
}
@media (max-width: 425px) {
    .mobile-hidden {
        display: none;
    }

    .upcoming-container {
        padding: 0px;
    }

    #ue-banner {
        background-color: #ffffff;
        padding: 10px 0 10px 20px;
        border-bottom: 1px solid rgba(50, 50, 49, 0.05);
    }
    .ue-white-background {
        margin-bottom: 0px;
        border-bottom: 1px solid rgba(50, 50, 49, 0.05);
    }

    .information-holder {
        width: 80%;
    }

    .information-row {
        display: inline-block;
    }

    .cls-holder {
        margin-top: 20px;
    }
}
