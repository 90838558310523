.redirect-container {
    text-align: center;
    padding: 100px 0px 150px 0px;
    font-size: 20px;
    background-color: white;
}

.redirect-block {
    font-size: 20px;
}
.loading-ellipsis {
    position: absolute;
}

.loading-ellipsis:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 2000ms infinite;
    animation: ellipsis steps(4, end) 2000ms infinite;
    content: "\2026";
    width: 0px;
}

.redirect-gif {
    width: 45vw;
    min-width: 250px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}
