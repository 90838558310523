body {
    background-color: #f5f5f5;
}

.parent-body {
    padding-top: 30px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
}

.modal-parent-body {
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
}

.center-text {
    text-align: center;
}

#reset-column {
    margin-left: 25%;
}

.questions {
    position: relative;
    width: 100%;
}

.remember-me-checkbox {
    display: inline-block;
    position: absolute;
    right: 95px;
    margin-top: 5px !important;
}

.remember-me-label {
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 26px;
    max-width: 100%;
    color: #b2b2b2;
    position: absolute;
    right: 0;
}

@media (max-width: 768px) {
    #reset-column {
        margin-left: 0;
    }
}

#email-activation-column {
    float: none;
    margin: 0;
    width: auto;
}

#changing-email {
    margin-left: 14%;
}

#success-old {
    float: none;
    margin-left: 0 !important;
}

@media (max-width: 767px) {
    #email-activation-column {
        margin-left: 0;
    }

    #changing-email {
        margin-left: 0;
    }

    .center-text {
        text-align: left;
    }

    #success-old {
        float: left;
    }
}

.login-section {
    background-color: white;
    padding: 20px;
    width: 100%;
    min-height: 366.5px;
}

.modal-section {
    background-color: white;
    width: 100%;
    min-height: 366.5px;
}

.register-section {
    background-color: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    min-height: 394.5px;
}

.login-settings-tool-tip {
    float: right;
    height: 20px;
    width: 20px;
    background-image: url("assets/images/form-tooltip-grey.png");
    text-decoration-color: rgb(158, 158, 158);
    color: rgb(158, 158, 158);
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    z-index: -1;
    cursor: pointer;
}

.settings-tool-tip:hover {
    visibility: visible;
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    background-color: rgb(158, 158, 158);
    overflow: none;
    line-height: 12px;
    right: 25px;
}

.glyph-login {
    padding-left: 20px;
    font-size: 14px;
}

.fb-logo {
    height: 27px;
    width: 21px;
    margin: 0;
    z-index: 1;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    background-size: 21px 27px;
    left: 15px;
    bottom: -36px;
    padding-right: 7px;
    border-right: 1px solid rgb(149, 172, 223);
}

.form-label {
    display: block;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: rgb(128, 128, 128);
    line-height: 14px;
}

.form-label-link {
    display: inline-block;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #b2b2b2;
    transition: color 0.7s;
    text-decoration-line: none;
    font-size: 13px;
}

.form-label-link:hover {
    color: rgb(71, 71, 71);
    cursor: pointer;
}

.login-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: rgb(51, 51, 51);
}

.form-row {
    margin: 0;
}

.form-input {
    width: 100% !important;
    height: 40px;
    max-width: 400px;
    padding-left: 15px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 4px;
}

.username-input {
    width: 90% !important;
    height: 40px;
    padding-left: 15px;
    max-width: 400px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 4px;
}

.form-section {
    margin: 0;
    margin-top: 14px;
}

.username-section {
    position: relative;
    margin: 0;
}

.login-btn-container {
    text-align: right;
}

#resend-btn-row {
    max-width: 400px;
}

.login-span {
    font-size: 18px;
    position: relative;
    font-weight: 400;
    width: 15%;
    text-align: center;
    padding-top: 7px;
    font-family: Arial, Helvetica, sans-serif;
}

.login-line {
    width: 42.5%;
    color: rgb(178, 178, 178);
}

.fb-login-btn {
    width: 100%;
    height: 44px;
    padding: 13px 14px 13px 50px;
    font-family: Helvetica Neue, Helvetica, Arial, serif;
    font-size: 18px;
    color: #fff;
    background-color: #4a68a8;
    line-height: 18px;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    transition: 0.7s;
    position: relative;
    cursor: pointer;
}

.terms-row {
    position: relative;
    margin: 0;
    margin-top: 15px;
}

.terms-label {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 28px;
    padding-left: 34px;
    max-width: 100%;
    color: #b2b2b2;
}

.terms-label a {
    color: #b2b2b2;
    transition: color 0.7s;
    text-decoration-line: none;
}

.terms-label a:hover {
    color: rgb(71, 71, 71);
}

#tc-checkbox {
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    height: 28px;
    width: 28px;
    margin: -1px;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    font-size: 20px;
    font-weight: 400;
    z-index: 1;
    outline-color: rgb(128, 128, 128);
    color: rgb(128, 128, 128);
    box-shadow: none;
    cursor: pointer;
}

p.errorMsg {
    margin-left: 15px;
    color: rgb(239, 68, 68);
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    float: left;
}

p.successMsg {
    margin-left: 15px;
    color: rgb(108, 202, 0);
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    float: left;
}

#spinner {
    height: 100px;
    width: 100px;
    margin-top: 200px;
}

#spinner-reset {
    height: 20px;
    width: 20px;
    margin-top: 20px;
}

.social-media-login-button {
    width: 100%;
    height: 44px;
    padding: 13px 14px 13px 50px;
    font-family: Helvetica Neue, Helvetica, Arial, serif;
    font-size: 18px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    transition: 0.7s;
    position: relative;
    outline: none !important;
}

.google-button-style {
    background-color: #cc2d2d;
}

.google-btn {
    padding: 0;
}
.google-img {
    margin-top: -1px;
}

.fb-login-btn:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.social-media-image {
    height: 44px;
    position: absolute;
    z-index: 20;
    cursor: pointer;
}

@media (max-width: 425px) {
    .fb-login-btn {
        font-size: 4vw;
    }
}

#reset-pass-banner {
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 10px;
    background-color: lightgreen;
    color: green;
}

.login-remember-block {
    text-align: right;
    float: right;
    right: 0;
}

.forgotten-user-block {
    display: inline;
}
