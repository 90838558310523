.profile-settings-selected {
    background-color: #e1e1e1;
}

.settings-container {
    padding: 0 15px 60px 15px;
}

.white-background {
    background-color: #fff;
}
.grey-background {
    background-color: #eaeaea;
}
.light-grey-background {
    background-color: #f5f5f5;
}

.setting-style-content-padding {
    padding: 20px;
}

.setting-selectable-tiles {
    outline: 1px solid #d5d5d5;
    width: 100%;
}

.maximum-width {
    width: 100%;
    overflow: hidden;
}

.setting-img-sizing {
    width: 20px;
    margin-left: 5px;
}

#previous-position-container {
    min-height: 150px;
    margin-left: 1%;
    width: 58%;
}

.tile-container {
    box-shadow: 0px 8px 7px 0 rgba(0, 0, 0, 0.15);
    float: left;
    width: 23%;
    margin-right: 2%;
    position: relative;
}

.inline-items {
    display: inline-block;
}

.setting-text-area-fields {
    font-family: "Nunito Sans", sans-serif;
}
.setting-font-style {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.67;
    letter-spacing: 0.7px;
    text-align: center;
    color: #323231;
}
.setting-panel-big-font-size-resizable {
    font-size: 18px;
}

.setting-panel-layer-2 {
    margin-left: 40px;
}

.setting-panel-big-font-size-static {
    font-size: 18px;
}

.remove-ref-icon {
    margin-left: 85%;
}

.web-ref-save-btn {
    width: 20%;
    padding-top: 20px;
    margin-left: 20px;
}

.ref-name-list {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 3px;
    padding-top: 3px;
    width: 100%;
}

.button-holder {
    position: relative;
}

.center-text {
    text-align: center;
}

.align-middle {
    vertical-align: middle;
}

.cross-img-container {
    width: 15%;
    display: inline-block;
}

.success-color {
    color: #2db34b;
}

.error-color {
    color: #ef4223;
}

input[type="text"].setting-input-fields {
    font-family: "Nunito Sans", sans-serif;
}

.setting-input-fields::-webkit-input-placeholder {
    font-family: "Nunito Sans", sans-serif;
}

.setting-input-fields:-moz-placeholder {
    /* Firefox 18- */
    font-family: "Nunito Sans", sans-serif;
}

.setting-input-fields::-moz-placeholder {
    /* Firefox 19+ */
    font-family: "Nunito Sans", sans-serif;
}

.setting-input-fields:-ms-input-placeholder {
    font-family: "Nunito Sans", sans-serif;
}

.ref-name {
    width: 85%;
    display: inline-block;
    text-align: left;
}

.social-panel-title-spacing {
    margin-left: 30px;
}
.grey-line {
    background-color: #d5d5d5;
    height: 2px;
    margin-left: 25px;
    margin-right: 20px;
}

.setting-config-container {
    overflow: hidden;
    margin-left: 25%;
    width: 75%;
    border-radius: 10px;
    box-shadow: 0px 8px 13px 0 rgba(0, 0, 0, 0.15);
}

.settings-btn {
    display: inline-block;
    width: 20%;
}

#privacy-delete-btn {
    margin-left: 2%;
}

.ep-about-input {
    width: 75%;
}

.save-btn {
    margin-left: 2%;
}

.cancel-btn {
    margin-left: 25%;
}

.setting-heading-font-size {
    font-size: 14px;
}

.setting-input-fields {
    width: 100%;
    border-radius: 10px;
    border: solid 1px #b7b7b7;
    height: 40px;
}

.item-array-list {
    width: 50%;
    margin-left: 1%;
    min-height: 150px;
    overflow-y: auto;
}
.setting-text-area-fields {
    resize: none;
    border-radius: 10px;
    border: solid 1px #b7b7b7;
}

.privacy-panel-spacing {
    margin-left: 30px;
}

#location-input-container {
    position: relative;
}

#location-image {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 15px;
    margin-right: 10px;
}

.align-right {
    float: right;
}

.no-bot-margin {
    margin-bottom: 0px;
}

.no-padding {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.input-fields-container {
    width: 30%;
    float: left;
    display: inline-block;
    white-space: nowrap;
}

.setting-general-style {
    position: relative;
    min-height: 1px;
    padding-right: 1%;
    padding-left: 1%;
}

.link-panel-spacing {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    float: right;
    width: 50%;
}
#setting-save-button {
    margin-left: 75%;
    width: 20%;
    padding-top: 1vh;
}

.greyed-out-btn,
.greyed-out-btn:hover,
.greyed-out-btn:active,
.greyed-out-btn:focus {
    background: grey !important;
    border: 3px solid grey !important;
    color: grey !important;
}

.disable-cursor {
    cursor: not-allowed;
}

.username-form-input {
    margin-right: 5%;
    width: 70%;
    display: inline;
    margin-bottom: 0px;
    font-family: "Nunito Sans", sans-serif;
}

.social-media-info {
    height: 100%;
    display: inline-block;
}

.social-username-panel {
    width: 100%;
    display: inline-block;
}

.link-text {
    display: inline;
    margin-right: 5%;
    color: #29a2ff;
    font-weight: 700;
}

.clickable-item {
    cursor: pointer;
}

.account-name {
    display: inline;
    color: #555;
    font-family: "Nunito Sans", sans-serif;
}

.cross-img-sizing {
    margin-left: 10px;
    margin-right: 10px;
}

.reference-container {
    padding-top: 10px;
    height: 100%;
}

#reference-plus {
    margin-left: 20px;
}
.add-reference-input {
    width: 50%;
    margin-left: 20px;
    display: inline-block;
}

.account-name-holder {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 300px;
}

.remove-icon-holder {
    display: inline-block;
    vertical-align: middle;
}

.bottom-spacing {
    margin-bottom: 20px;
}

.privacy-panel-btn {
    margin-top: 20px;
}

.settings-btn-panel-spacing {
    padding-top: 30px;
    margin-bottom: 20px;
}

.title-bottom-padding {
    padding-bottom: 5px;
}

.banner-message {
    margin-bottom: 20px;
    text-align: center;
}

.setting-tab-redirect-link {
    flex: 1;
}

.no-link-styling,
.no-link-styling:hover,
.no-link-styling:active,
.no-link-styling:visited,
.no-link-styling:focus {
    text-decoration: none;
}

.api-key-table .rt-thead .rt-th,
.api-key-table .rt-thead .rt-td {
    border-right: none;
}

.api-key-table .rt-tbody .rt-td {
    border-right: none;
}

.api-key-table .rt-thead .rt-th,
.api-key-table .rt-thead .rt-td {
    background-color: #f6f6f6;
    text-align: left;
}

.developer-container {
    font-family: "Nunito Sans", sans-serif;
}

.dc-api-client-header {
    font-size: 35px;
    margin-left: 10px;
    font-weight: bold;
}

.dc-api-client-button {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dc-create-block-button-container {
    width: 100%;
}

.dc-api-client-properties {
    font-size: 20px;
    margin-left: 10px;
}

.dc-api-client-name-input-form {
    width: 100%;
}

.dc-api-client-name-form {
    width: 100%;
    margin: 10px 0px 10px 30px;
}

.dc-api-client-name-label {
    font-size: 15px;
}

.dc-api-client-name-label,
.dc-api-client-name-input {
    display: inline-block;
    margin: 5px;
}

#remove-api-key-icon {
    color: red;
    cursor: pointer;
}

.api-copy-text:hover {
    background-color: #c8c8c8;
    cursor: pointer;
}

.api-limits-info {
    margin-bottom: 10px;
    font-weight: bold;
    display: inline-block;
}

.api-settings-usage-limit-block {
    margin-top: 20px;
}

.api-settings-usage-limit-title {
    font-size: 20px;
    font-weight: bold;
}

.org-api-expiry-text {
    display: block;
}

.bold-font {
    font-weight: bold;
}

@font-face {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    src: url(assets/fonts/avenir-next-regular.ttf);
}

@media (max-width: 1024px) {
    .item-array-list {
        width: 80%;
    }

    .add-reference-input {
        width: 80%;
    }

    .input-fields-container {
        width: 50%;
    }

    #previous-position-container {
        width: 98%;
    }
    .privacy-panel-spacing {
        margin-left: 0;
    }
}

@media (min-width: 1024px) {
    .cancel-btn {
        margin-left: 45%;
    }

    .info-cancel-btn {
        margin-left: 60%;
    }
    .settings-btn {
        width: 15%;
    }
}

@media (max-width: 800px) {
    .settings-btn {
        width: 25%;
    }

    .cancel-btn {
        margin-left: 15%;
    }
    .info-cancel-btn {
        margin-left: 25%;
    }
}

@media (min-width: 769px) {
    .mobile-view {
        display: none;
    }

    .web-view {
        display: block;
    }

    .sm-names {
        display: inline;
        padding-left: 5px;
    }

    .mobile-input-field::-webkit-input-placeholder {
        color: #fff;
    }

    .mobile-input-field:-moz-placeholder {
        color: #fff;
    }

    .mobile-input-field::-moz-placeholder {
        color: #fff;
    }

    .mobile-input-field:-ms-input-placeholder {
        color: #fff;
    }
}
@media (max-width: 850px) and (min-width: 769px) {
    #setting-save-button {
        margin-left: 70%;
        min-width: 100px;
    }

    .setting-panel-selector-font-size {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .settings-container {
        padding: 0;
    }

    .no-bot-margin {
        margin-bottom: 10px;
    }

    .setting-general-style {
        padding-top: 10px;
    }

    .account-name-holder {
        max-width: 350px;
    }
    .ref-name-list {
        font-size: 3vw;
    }

    input[type="text"].setting-input-fields {
        font-size: 3vw;
    }

    .mobile-view {
        display: block;
    }

    .banner-message {
        padding: 20px;
        margin-bottom: 0px;
    }

    .web-view {
        display: none;
    }

    .settings-btn-panel-spacing {
        padding-top: 0px;
    }

    .setting-heading-font-size {
        font-size: 3vw;
    }

    .profile-settings-selected {
        background-color: #fff;
    }

    .mobile-lining {
        border-left: 1px solid #d5d5d5;
    }

    .selector-container {
        padding: 10px;
        background: #fff;
        border-top: 1px solid #d5d5d5;
        border-bottom: 1px solid #d5d5d5;
    }
    .mobile-text-unselected {
        color: #d5d5d5;
    }

    .media-spacing {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .input-fields-container {
        width: 100%;
    }

    .privacy-panel-spacing {
        margin-left: 0;
    }

    .inline-items {
        width: 90%;
    }

    .top-spacing {
        margin-top: 10px;
    }

    .setting-panel-big-font-size-resizable {
        font-size: 2vw;
        line-height: 1.2;
        font-style: normal;
        font-weight: 500;
        font-stretch: normal;
        letter-spacing: 0.4px;
    }

    .tile-container {
        box-shadow: 0px 0px 0px 0;
        width: 100%;
        display: flex;
        float: none;
    }

    .setting-selectable-tiles {
        vertical-align: middle;
        text-align: center;
        outline: 0px;
    }

    .setting-config-container {
        margin-left: 0%;
        width: 100%;
        border-radius: 0px;
        box-shadow: 0px 0px 0px 0;
    }

    .ep-about-input {
        width: 100%;
    }

    #previous-position-container {
        width: 98%;
    }

    .add-reference-input {
        width: 65%;
        margin-left: 5px;
    }

    .ep-about-input {
        width: 100%;
    }

    .setting-input-fields {
        display: inline-block;
        height: 40px;
    }

    #location-image {
        position: absolute;
        bottom: 18px;
        right: 40%;
        width: 15px;
    }

    .cancel-btn {
        margin-left: 0%;
        padding-top: 30px;
        width: 49%;
        display: inline-block;
    }

    .save-btn {
        margin-left: 2%;
        width: 49%;
        display: inline-block;
    }

    #privacy-delete-btn {
        text-align: left;
        display: inline-block;
        padding-bottom: 20px;
    }

    .web-ref-save-btn {
        width: 80%;
    }

    .social-panel-title-spacing {
        margin-left: 5px;
        margin-top: 10px;
    }
    .social-panel-div {
        margin-top: 30px;
    }

    .setting-style-content-padding {
        padding: 5px;
    }

    .link-panel-spacing {
        padding-top: 0px;
        margin-left: 3%;
        float: right;
        width: auto;
    }

    .account-name {
        font-size: 3vw;
    }
    .grey-line {
        margin-left: 0px;
        margin-right: 0px;
        opacity: 0.5;
    }

    .link-text {
        margin-left: 4%;
        margin-right: 0%;
    }

    .username-form-input {
        width: 75%;
        margin-right: 0%;
        display: inline;
        margin-bottom: 0px;
    }

    .add-reference-container {
        overflow: hidden;
    }

    .item-array-list {
        width: 96%;
    }

    .setting-input-fields::-webkit-input-placeholder {
        font-size: 3vw;
    }

    .setting-input-fields:-moz-placeholder {
        /* Firefox 18- */
        font-size: 3vw;
    }

    .setting-input-fields::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 3vw;
    }

    .setting-input-fields:-ms-input-placeholder {
        font-size: 3vw;
    }

    .mobile-input-field::-webkit-input-placeholder {
        text-align: left;
        font-style: 3vw;
    }

    .mobile-input-field:-moz-placeholder {
        /* Firefox 18- */
        text-align: left;
        font-style: 3vw;
    }

    .mobile-input-field::-moz-placeholder {
        /* Firefox 19+ */
        text-align: left;
        font-style: 3vw;
    }

    .mobile-input-field:-ms-input-placeholder {
        text-align: left;
        font-style: 3vw;
    }

    .bottom-spacing {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .delete-btn-container {
        width: 30%;
        display: inline-block;
    }

    #delete-btn-tooltip {
        display: inline-block;
    }
}

@media (max-width: 550px) {
    .setting-input-fields {
        height: 35px;
    }

    .setting-input-fields::-webkit-input-placeholder {
        font-size: 3vw;
    }

    .setting-input-fields:-moz-placeholder {
        /* Firefox 18- */
        font-size: 3vw;
    }

    .setting-input-fields::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 3vw;
    }

    .setting-input-fields:-ms-input-placeholder {
        font-size: 3vw;
    }

    input[type="text"].setting-input-fields {
        font-size: 4vw;
    }

    .ref-name {
        font-size: 4vw;
    }

    .account-name-holder {
        max-width: 300px;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .account-name-holder {
        max-width: 200px;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .account-name-holder {
        max-width: 250px;
    }
}

@media (min-width: 426px) and (max-width: 500px) {
    .account-name-holder {
        max-width: 250px;
    }
}

@media (min-width: 551px) and (max-width: 600px) {
    .account-name-holder {
        max-width: 300px;
    }
}

.api-user-information-container {
    margin-bottom: 80px;
}

.api-client-table {
    display: block;
}

.dev-api-secondary-key-container {
    margin-top: 20px;
}

.org-api-expiry-information {
    vertical-align: top;
}

.org-api-expiring-key-buttons-container {
    float: right;
}

#dc-api-promote-button,
#dc-api-restore-button {
    height: 35px;
    margin-left: 10px;
}

#org-api-key-hide-toggle {
    margin-left: 4px;
}

#org-api-key-hide-toggle,
#copy-org-api-key {
    float: right;
}

.org-api-expiring-key-buttons-container {
    margin-left: 30px;
}
