#invite-comms-label {
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.7px;
    margin-left: 2px;
}

#add-invite-input {
    width: 450px;
    height: 54px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #626060;
    outline: none !important;
}

#team-organiser-container {
    border-radius: 10px;
    border: 1px solid #cccccc;
}

.invite-container {
    border-radius: 19.5px;
    border: 1px solid #cccccc;
    padding: 10px;
    height: 40px;
    width: 140px;
    text-align: center;
    margin: 10px 5px;
    display: inline-block;
}

#invited-user {
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 1;
    height: 100%;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

#remove-invite-icon {
    right: -4px;
    top: -3px;
}

.team-divider {
    margin: 0;
}

.drag-info-box {
    border: 1px dotted #b7b7b7;
    padding: 10px;
    width: 235px;
    margin: 10px;
    margin-left: 7px;
    display: inline-block;
    vertical-align: top;
}

.empty-info-box {
    border: 1px dotted #b7b7b7;
    padding: 10px;
    width: 212px;
    top: 20px;
    left: 20px;
    position: absolute;
}

.team-name-input {
    height: 45px;
    width: 275px;
    border: 1px solid rgba(183, 183, 183, 0.6);
    border-radius: 10px;
    padding: 10px 20px;
    line-height: 1;
    height: 100%;
    outline: none !important;
}

.draggable-username {
    display: inline-block;
    vertical-align: top;
}

.team-language-dropdown-select {
    display: inline-block;
    width: 120px;
    margin-right: 20px;
}

.team-language-dropdown-select > .css-vj8t7z {
    border-radius: 10px !important;
}

.leader-column-title {
    display: block;
    width: 100%;
    padding-left: 57px;
    padding-bottom: 2px;
    padding-top: 3px;
    background-color: rgb(241, 241, 241);
    font-size: 16px;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.notify-comm-label {
    display: inline-block;
    margin: 0 15px;
}

.notify-select-options {
    text-align: right;
}

#select-all-label,
#deselect-all-label {
    cursor: pointer;
}

.invite-suggestions {
    font-size: 20px;
    position: absolute;
    width: 450px;
    background: #555;
    border: 1px solid #cccccc;
    z-index: 30;
    border-radius: 10px;
    overflow: hidden;
}
