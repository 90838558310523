.grow-fade-in {
    animation: growFadeIn 1s;
    -webkit-animation: growFadeIn 1s;
    -moz-animation: growFadeIn 1s;
    -o-animation: growFadeIn 1s;
    -ms-animation: growFadeIn 1s;
}

@keyframes growFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-moz-keyframes growFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes growFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-o-keyframes growFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-ms-keyframes growFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
