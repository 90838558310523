.container {
    background-color: #eaeaea;
    display: flex;
    height: auto !important;
    flex-direction: column;
    padding: 0;
    min-height: 100vh;
}

#pageBody {
    padding: 0;
    padding-bottom: 10px;
    background-color: #eaeaea;
    flex: 1;
    height: 100%;
    min-height: 90vh;
}

#loading-screen {
    text-align: center;
    margin-top: 300px;
}

.slick-next,
.slick-prev {
    color: black !important;
    display: inline-block !important;
    top: 50% !important;
    width: 20px !important;
    height: 49px !important;
    border-radius: 25px !important;
    z-index: 68 !important;
}

.slick-prev {
    left: -30px !important;
    opacity: 0.5 !important;
    background-image: url("./assets/images/back-arrow.png") !important;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
}

.slick-prev:hover {
    opacity: 1 !important;
}

.slick-prev::before,
.slick-next::before {
    content: unset !important;
}

.slick-next {
    right: -30px !important;
    opacity: 0.5 !important;
    background-image: url("./assets/images/next-arrow.png") !important;
    background-repeat: no-repeat !important;
    background-position: 56% !important;
}

.slick-next:hover {
    opacity: 1 !important;
}

.slick-disabled {
    visibility: hidden !important;
}

input[type="text"],
textarea {
    outline: none;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
}

@media screen and (max-width: 1024px) {
    .slick-next,
    .slick-prev {
        width: 5vw !important;
        height: 5vw !important;
        background-size: 30% !important;
    }

    .slick-prev {
        left: -38px !important;
    }

    .slick-next {
        right: -38px !important;
    }
}

@media screen and (max-width: 860px) {
    .slick-next {
        right: -6% !important;
    }

    .slick-prev {
        left: -6% !important;
    }
}

@media screen and (max-width: 768px) {
    #pageBody {
        padding: 0;
        background-color: "#eaeae";
        margin-top: 0;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
