/* width */
.commentator-check-scroll-bar-styling::-webkit-scrollbar {
    height: 7px;
    width: 4px;
    padding-right: 1px;
}

/* Track */
.commentator-check-scroll-bar-styling::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
.commentator-check-scroll-bar-styling::-webkit-scrollbar-corner {
    color: black;
}

.commentator-check-scroll-bar-styling::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 50px;
}

.commentator-check-scroll-bar-styling::-webkit-scrollbar-thumb:horizontal {
    background: #c4c4c4;
    border-radius: 10px;
}
