.footer-wrapper {
    position: relative;
    background-color: #dddddd;
    padding: 80px 10px 40px 10px;
}

.spalk-logo {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 114px;
    height: 115px;
}

.footer-body {
    text-align: center;
    height: 100%;
    padding-top: 30px;
    /* while no logo
    padding-right: 15%;
    width: 80%;
    margin-left: 20%; */
}

.footer-copyright-block {
    color: rgba(84, 89, 95, 0.59);
    font-size: 12px;
}

.footer-copyright-block a {
    color: #666;
}

.footer-spalk-text-img-container {
    width: 100%;
    text-align: center;
}

.footer-spalk-text-img {
    width: 300px;
}

.footer-heading-text {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
}

.footer-link-div {
    padding: 10px 25px;
    display: inline-block;
    color: #54595f;
}

.footer-link {
    font-family: "Nunito Sans", sans-serif;
    font-size: 15px;
    color: #6b6969 !important;
    text-decoration: none !important;
    line-height: 1;
}

.footer-img-container {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.footer-img-block {
    padding: 10px 25px 25px 25px;
    display: inline-block;
}

.footer-social-icon {
    width: 20px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .footer-spalk-text-img {
        width: 55%;
        max-width: 100%;
    }

    .footer-heading-text {
        font-size: 21px;
    }

    .footer-wrapper {
        margin-bottom: 66px;
    }

    .footer-img-block {
        padding: 10px 15px 20px 15px;
    }

    .footer-body {
        padding-top: 30px;
        padding-right: 0;
        /* while no logo
        width: 70%;
        padding-left: 10%; */
    }

    .footer-link-div {
        width: 50%;
        padding-bottom: 10px;
        vertical-align: top;
    }

    .spalk-logo {
        left: 3%;
    }

    .footer-link {
        font-size: 17px;
    }
}

@media screen and (max-width: 425px) {
    .footer-link-div {
        width: 100%;
        padding-bottom: 5px;
        vertical-align: top;
    }

    .footer-body {
        margin-right: 0;
        padding-top: 10px;
        /* while no logo
        width: 80%;
        padding-left: 20%; */
    }

    .spacer {
        top: 66px;
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 375px) {
    .footer-wrapper {
        margin-bottom: 61px;
    }
}
