.fader-handle {
    background: linear-gradient(180deg, #434242 0%, #4b4b4b 66.15%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
    border-radius: 5px;
    border: 3px solid;
    border-image-source: linear-gradient(
        rgb(96, 96, 96) -50%,
        rgba(14, 14, 14, 0) 14.06%,
        rgba(14, 14, 14, 0) 48.44%,
        rgba(14, 14, 14, 0) 86.46%,
        rgb(14, 14, 14) 200%
    );
}

.fader-handle-notch-inner-shadow {
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.8);
}
