.onboarding-body {
    min-height: calc(100vh - 5vw);
}

.icon * {
    stroke: white !important;
    stroke-width: 1 !important;
}

.icon:hover * {
    stroke-width: 1.5px !important;
}

.bold-icon * {
    stroke-width: 3 !important;
}

.thin-icon * {
    stroke-width: 1 !important;
}
