.main-listen {
    margin: 0;
    height: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
}

.video-tile {
    height: 100%;
    min-height: 420px;
    width: 100%;
    max-width: 950px;
    margin: auto;
    float: none;
    padding: 15px;
    background-color: #fff;
}

#listen-iframe-wrap {
    margin: auto;
    max-width: 95%;
    margin-bottom: 20px;
}

.iframe-inner-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 42.5%;
}

#listen-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.video-name {
    text-align: left;
    width: 100%;
    margin-left: 15px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.event-title {
    padding: 0;
}

.link-to-listen {
    color: black;
    text-decoration: none !important;
}

.link-to-listen:hover {
    cursor: pointer;
    color: rgb(250, 160, 160);
}

.link-to-listen:focus {
    outline: none;
}

.share-icon {
    width: 25px;
    height: auto;
    padding-left: 0.3vw;
    margin-left: 2px;
    vertical-align: top;
    cursor: pointer;
}

.share-icon-font {
    font-size: 20px;
    color: #9e9e9e;
    cursor: pointer;
}

.share-icon-font:hover {
    color: black;
}

.sidebar-btn-row {
    margin: 0;
    padding-left: 20px;
    padding-right: 35px;
    margin-top: -4px;
}

.comm-btn {
    background-color: rgb(255, 215, 0);
    color: white;
    border-radius: 15px;
    border-color: rgb(255, 215, 0);
    width: 100%;
    height: 30px;
    padding: 0;
    font-size: 12px;
    vertical-align: top;
}

.comm-btn:hover {
    background-color: rgb(255, 215, 0);
    color: white;
    border-color: rgb(255, 215, 0);
}

.comm-btn:focus,
.comm-btn:active:hover {
    text-decoration: none;
    outline: none;
    background-color: rgb(255, 215, 0);
    color: white;
    border-color: rgb(255, 215, 0);
}

.comm-btn-link {
    color: white;
}

.comm-btn-link:hover {
    text-decoration: none;
    color: white;
}

.video-description {
    text-align: left;
    margin: 0;
    font-weight: 400;
    margin-top: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #9e9e9e;
    word-wrap: break-word;
}

.video-description a {
    color: rgb(158, 158, 158);
}

.video-description a:hover {
    cursor: pointer;
    text-decoration: none;
    transition: color 0.5s ease;
    color: rgb(51, 51, 51);
}

.share-icon-wrapper {
    text-align: right;
}

@media screen and (max-width: 888px) {
    #listen-iframe-wrap {
        padding-bottom: 250px;
        border-bottom: 1px solid lightgray;
    }

    #listen-iframe {
        height: 300%;
        overflow: hidden;
    }

    .sidebar-btn-row {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .video-name {
        flex-wrap: wrap;
    }

    .event-title {
        flex-basis: 100%;
        padding-right: 10px;
        margin-left: -10px;
        margin-top: 15px;
        order: 3;
    }

    .share-icon-wrapper {
        margin-top: -25px;
        flex-basis: 75%;
        margin-left: 25%;
        order: 2;
    }

    .no-commentate-btn-margin {
        margin-top: 0px;
    }

    .share-icon-font {
        margin-top: 0 !important;
    }

    .sidebar-btn-row {
        flex-basis: 50%;
        margin: 0;
        left: -10px;
        padding: 0;
        order: 1;
    }

    .video-description {
        width: 100%;
        padding: 5px;
    }

    #listen-iframe-wrap {
        padding-bottom: 240px;
    }
}

@media screen and (max-width: 425px) {
    .main-listen {
        padding: 0;
    }
    .video-description {
        margin-top: 0;
    }
}

@media screen and (max-width: 414px) {
    .comm-btn {
        font-size: 3vw;
    }

    .event-title {
        font-size: 3.6vw;
    }
}

@media screen and (max-width: 385px) {
    .event-title {
        margin-left: -15px;
    }

    .share-icon-wrapper {
        width: 65%;
        margin-left: 35%;
    }

    .sidebar-btn-row {
        left: -15px;
    }

    .video-description {
        padding: 0;
    }

    #event-description {
        display: inline-block;
        width: 100%;
        word-wrap: break-word;
    }
}

@media screen and (max-width: 350px) {
    .comm-btn {
        font-size: 11px;
    }
}
