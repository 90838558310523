@tailwind base;
@tailwind components;
@tailwind utilities;

/* fix for these being stripped out by something in the build pipeline */
:root {
    --empty: ;
}
*,
:after,
:before {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: var(--empty, /*!*/ /*!*/);
    --tw-pan-y: var(--empty, /*!*/ /*!*/);
    --tw-pinch-zoom: var(--empty, /*!*/ /*!*/);
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: var(--empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--empty, /*!*/ /*!*/);
    --tw-ring-inset: var(--empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: var(--empty, /*!*/ /*!*/);
    --tw-brightness: var(--empty, /*!*/ /*!*/);
    --tw-contrast: var(--empty, /*!*/ /*!*/);
    --tw-grayscale: var(--empty, /*!*/ /*!*/);
    --tw-hue-rotate: var(--empty, /*!*/ /*!*/);
    --tw-invert: var(--empty, /*!*/ /*!*/);
    --tw-saturate: var(--empty, /*!*/ /*!*/);
    --tw-sepia: var(--empty, /*!*/ /*!*/);
    --tw-drop-shadow: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-blur: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-brightness: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-contrast: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-invert: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-opacity: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-saturate: var(--empty, /*!*/ /*!*/);
    --tw-backdrop-sepia: var(--empty, /*!*/ /*!*/);
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

@layer components {
    .tw-tooltip {
        @apply tw-invisible tw-absolute;
    }

    .tw-has-tooltip:hover .tw-tooltip {
        @apply tw-visible tw-z-50;
    }

    .tw-display-1 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-7xl /* 72px */;
        line-height: 5rem; /* 80px */
        letter-spacing: -0.04em;
    }

    .tw-display-2 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-6xl /* 60px */;
        line-height: 4.5rem; /* 72px */
        letter-spacing: -0.04em;
    }

    .tw-heading-1 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-5xl /* 48px */;
        line-height: 3.5rem; /* 56px */
        letter-spacing: -0.04em;
    }

    .tw-heading-1-small {
        @apply tw-antialiased tw-font-inter tw-font-semibold tw-leading-10 /* 40px, */;
        font-size: 2.125rem; /* 34px */
        letter-spacing: -0.04em;
    }

    .tw-heading-2 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-leading-10 /* 40px, */;
        font-size: 2.125rem; /* 34px */
        letter-spacing: -0.02em;
    }

    .tw-heading-2-small {
        @apply tw-antialiased tw-font-inter tw-font-semibold tw-text-2xl /* 24px */ tw-leading-8 /* 32px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-3 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-2xl /* 24px */ tw-leading-8 /* 32px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-3-small {
        @apply tw-antialiased tw-font-nunito-sans tw-font-bold tw-text-xl /* 20px */ tw-leading-6 /* 24px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-4-small {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-xl /* 20px */ tw-leading-6 /* 24px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-4-small {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-base /* 16px */ tw-leading-5 /* 20px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-5 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-base /* 16px */ tw-leading-5 /* 20px */;
        letter-spacing: -0.02em;
    }

    .tw-heading-5-small {
        @apply tw-antialiased tw-font-nunito-sans tw-font-semibold tw-text-sm /* 14px */ tw-leading-5 /* 20px */;
        letter-spacing: -0.02em;
    }

    .tw-subheading {
        @apply tw-antialiased tw-font-nunito-sans tw-font-normal tw-text-xl /* 20px */ tw-leading-7 /* 28px */;
    }

    .tw-paragraph-1 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-normal tw-text-lg /* 18px */;
        line-height: 1.625rem; /* 26px */
    }

    .tw-paragraph-2 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-normal tw-text-base /* 16px */ tw-leading-6 /* 24px */;
    }

    .tw-paragraph-3 {
        @apply tw-antialiased tw-font-nunito-sans tw-font-normal tw-text-sm /* 14px */ tw-leading-5 /* 20px */;
    }

    .tw-caption {
        @apply tw-antialiased tw-font-nunito-sans tw-font-normal tw-text-xs /* 12px */;
        line-height: 1.125rem; /* 18px */
    }

    .tw-footer {
        @apply tw-antialiased tw-font-normal tw-font-nunito-sans;
        font-size: 0.625rem; /* 10px */
        line-height: 0.875rem;
        letter-spacing: 0.02em;
    }

    .tw-linecap-round {
        stroke-linecap: round;
    }

    .tw-stroke-all-descendants-1 * {
        @apply tw-stroke-1;
    }

    .tw-stroke-all-descendants-1\.5 * {
        stroke-width: 1.5;
    }

    .tw-stroke-all-descendants-2 * {
        stroke-width: 2;
    }

    .tw-stroke-all-descendants-2\.5 * {
        stroke-width: 2.5;
    }

    .tw-stroke-all-descendants-neutral-900 * {
        stroke: #0f172a;
    }

    .tw-organisation-members-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        align-items: stretch;
    }

    @media (min-width: 640px) {
        .tw-organisation-members-grid {
            grid-template-columns: max-content auto max-content min-content;
            row-gap: 4px;
        }
    }

    .tw-unset-all {
        all: unset;
    }
}

/* ------------------------------------------- INPUTS ------------------------------------------- */
/*  Copied from the bootstrap source for .form-control
We have extended the original form-control from bootstrap, so this is another
extension that makes it smaller */

.compact-input {
    height: 26px;
    padding: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.compact-input:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.compact-input::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.compact-input:-ms-input-placeholder {
    color: #999;
}

.compact-input::-webkit-input-placeholder {
    color: #999;
}

.compact-input::-ms-expand {
    background-color: transparent;
    border: 0;
}

.compact-input[disabled],
.compact-input[readonly],
fieldset[disabled] .compact-input {
    background-color: #eee;
    opacity: 1;
}

.compact-input[disabled],
fieldset[disabled] .compact-input {
    cursor: not-allowed;
}

/**
* Rangeslider
*/
.rangeslider {
    margin: 20px 0;
    position: relative;
    background: #e6e6e6;
    -ms-touch-action: none;
    touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
}
/**
  * Rangeslider - Horizontal slider
  */
.rangeslider-horizontal {
    height: 12px;
    border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #7cb342;
    border-radius: 10px;
    top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
    content: " ";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: #dadada;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
}
/**
  * Rangeslider - Vertical slider
  */
.rangeslider-vertical {
    margin: 20px auto;
    height: 150px;
    max-width: 10px;
    background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
    position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
    width: 100%;
    background-color: #7cb342;
    box-shadow: none;
    bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
    width: 30px;
    height: 10px;
    left: -10px;
    box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
    left: -100%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(0, 0, 0, 0.8);
    left: 100%;
    top: 12px;
}
/**
  * Rangeslider - Reverse
  */
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
    right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
    top: 0;
    bottom: inherit;
}
/**
  * Rangeslider - Labels
  */
.rangeslider__labels {
    position: relative;
}
.rangeslider-vertical .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    content: "";
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;
        --ring: 215 20.2% 65.1%;
        --radius: 0.5rem;
    }
   
    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;
        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;
        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;
        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;
        --border: 216 34% 17%;
        --input: 216 34% 17%;
        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;
        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;
        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;
        --ring: 216 34% 17%;
    }
}
