.redundant-connection-state {
    background-image: url(./redundant-connection-sprites.svg);
    background-size: 60px 28px;
    margin-left: -5px;

    /* middle link */
    background-position-y: -21px;
}

.redundant-connection-state.green {
    background-position-x: 0;
}

.redundant-connection-state.yellow {
    background-position-x: -12px;
    animation: 0.8s redundant-connection-flash linear infinite;
}

.redundant-connection-state.orange {
    background-position-x: -24px;
}

.redundant-connection-state.red {
    background-position-x: -36px;
}

.redundant-connection-state.grey {
    background-position-x: -48px;
}

.redundant-connection-state:first-child {
    /* start link */
    background-position-y: -7px;
    margin-left: 0;
}

.redundant-connection-state:last-child {
    /* end link */
    background-position-y: -14px;
}

.redundant-connection-state.single {
    background-position-y: 0;
}

.redundant-connection-interface-detail:not(:last-child) {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
}

@keyframes redundant-connection-flash {
    0%,
    100% {
        opacity: 100%;
    }

    50% {
        opacity: 60%;
    }
}
