.commentate-body {
    padding-top: 30px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
    height: 100%;
}

.live-events-component {
    background: #fff;
    margin: 0 10px 10px;
    padding: 20px 0 10px 20px;
}

.commentate-title {
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

.link-video,
#link-video {
    margin-bottom: 5px;
    color: #9e9e9e;
    text-decoration: none;
    transition: 0.7s;
    font-size: 13px;
}

.link-video:hover,
#link-video:hover {
    color: #000333;
    text-decoration: none;
}

.live-event-info {
    padding-right: 0;
}

.live-event-description {
    color: #9e9e9e;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-right: 5px;
}

.live-event-description::-webkit-scrollbar {
    width: 5px;
}

.live-event-description::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
}

.live-event-title {
    margin-left: 0;
    margin-right: 0;
}

.live-event-image-wrapper {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    max-width: 200px;
    height: 113px;
}

.live-event-image {
    width: 100%;
    max-width: 200px;
    max-height: 113px;
    height: 100%;
}

.mic-overlay {
    position: absolute;
    right: 0%;
    bottom: 6px;
}

@media screen and (max-width: 767px) {
    .live-events-component {
        padding: 20px 0 10px 5px;
        margin: 0 0 10px;
    }
    .content-row {
        margin: 10px 0 15px 0;
    }
    .live-event-info {
        font-size: 13px;
        padding-right: 10px;
    }
    .live-event-image-wrapper {
        padding-left: 10px;
    }
}

@media screen and (max-width: 500px) {
    .live-event-image-wrapper {
        height: 100%;
        min-height: 80px;
    }
    .live-event-image {
        min-height: 80px;
    }
}

@media screen and (max-width: 468px) {
    .mic-overlay {
        height: 6vw;
        width: 6vw;
    }
    .live-event-info {
        font-size: 11.5px;
    }
}

@media screen and (max-width: 425px) {
    .live-event-description {
        max-height: 83px;
    }
}
