.upcoming-events-component {
    background: #fff;
    padding: 0;
}

.empty-schedule {
    margin: 0;
    text-align: center;
    padding: 10px;
}

.empty-upcoming-events-title {
    font-size: 13px;
    display: inline-block;
    font-family: "Nunito Sans", sans-serif;
}

.content-row {
    margin: 10px 0 10px 0;
}

.upcoming-events-img-container {
    width: 20%;
    display: inline-block;
    margin-right: 5%;
    vertical-align: top;
}

.upcoming-events-img {
    width: 100%;
    vertical-align: super;
    padding-top: 3px;
}

.upcoming-event-tile-info-container {
    width: 75%;
    display: inline-block;
}

.upcoming-event-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: "Nunito Sans", sans-serif;
}

#upcoming-event-title {
    margin-bottom: 3px;
    display: block;
    line-height: 1;
}

.upcoming-event-info {
    margin: 0;
    font-size: 13px;
    color: #323231;
    font-family: "Nunito Sans", sans-serif;
    line-height: 1;
}

#upcoming-event-start-time {
    margin-bottom: 5px;
    display: block;
}

.upcoming-event-tile-link {
    display: block;
    color: #9e9e9e;
    text-decoration: none;
    font-size: 12px;
}

.upcoming-event-line {
    margin: 0;
    border-bottom: 1px solid rgba(50, 50, 49, 0.05);
    border-top: unset;
    margin-top: 10px;
}

.link-to-watch-event {
    color: #323231;
}

@media screen and (max-width: 768px) {
    .content-row {
        margin: 10px 0 15px 0;
    }

    .upcoming-events-img-container {
        width: 11%;
        margin-right: 3%;
    }

    .upcoming-event-tile-info-container {
        width: 50%;
    }
}
