/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

#review-ai-modal .modal-dialog {
    width: 85% !important;
    min-width: 1250px !important;
    max-width: 1950px !important;
}
