.commentator-dropdown-container {
    padding-top: 15px;
}

.commentator-dropdown-row {
    padding: 0 20px;
}

.commentator-soundcheck-block {
    height: 39px;
    margin-top: 8px;
    border-radius: 19.5px;
    background-color: #4c4d4c;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0px 4px 0px 10px;
}

.commentator-soundcheck-block-in-cms {
    padding: 4px;
}

.commentator-partition {
    border-right: 3px solid #d9d9d9;
    height: 55px;
}

.commentator-dropdown-vertical-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.commentator-img-styling {
    border-radius: 300px;
    width: 45px;
    height: 45px;
}

.commentator-img-styling-red-border {
    border: 2px red solid;
}

.commentator-soundcheck-text {
    color: white;
}

.comm-img-container {
    position: relative;
    vertical-align: middle;
}

.commentator-status {
    position: absolute;
    border-radius: 40px;
    width: 15px;
    height: 15px;
    bottom: 0px;
    right: 0px;
}

.commentator-img-label-container {
    position: absolute;
    top: -20px;
    text-align: center;
    width: 100%;
}

.small-comm-img-notifiers {
    height: 13px;
    margin-top: -5px;
}

.commentator-img-info-open .small-comm-img-notifiers {
    height: 0;
    width: 0;
    margin: 0;
}

.commentator-img-info-open > .commentator-img-label-container {
    visibility: visible;
    left: -10px;
    font-size: 12px;
}

.commentator-img-info-open .commentator-img-label {
    visibility: visible;
    font-size: 12px;
}

.comm-img-container:hover .commentator-img {
    padding-right: 30px;
    width: 73px;
    z-index: 1;
    cursor: pointer;
}

.comm-img-container:hover .comm-info-slider {
    visibility: visible;
    position: absolute;
    border-radius: 40px;
    text-align: right;
    width: 70px;
    height: 55px;
    top: -5px;
    left: -5px;
    background-color: #e1e1e1;
}

.comm-img-container:hover .commentator-status {
    right: 25px;
}

.comm-img-container:hover .commentator-img-info-open,
.commentator-img-info-open {
    padding-right: 10px;
    width: 260px;
    z-index: 1;
    cursor: pointer;
}

.comm-img-container:hover .commentator-img-info-open-4-btn,
.commentator-img-info-open-4-btn {
    width: 330px;
}

.comm-img-container:hover .commentator-img-info-open + .comm-info-slider,
.commentator-img-info-open + .comm-info-slider {
    visibility: visible;
    position: absolute;
    border-radius: 40px;
    text-align: right;
    width: 250px;
    height: 55px;
    top: -5px;
    left: -5px;
    background-color: #e1e1e1;
}

.comm-img-container:hover .commentator-img-info-open-4-btn + .comm-info-slider,
.commentator-img-info-open-4-btn + .comm-info-slider {
    width: 305px;
}

.comm-img-container:hover .commentator-img-info-open .commentator-status,
.commentator-img-info-open .commentator-status {
    right: 213px;
}

.comm-img-container:hover .commentator-img-info-open-4-btn .commentator-status,
.commentator-img-info-open-4-btn .commentator-status {
    right: 283px;
}

.comm-info-slider-open .commentary-control-container {
    background: unset;
    padding: unset;
    z-index: 2;
}

.comm-info-slider-open .utility-button-container {
    width: 55px !important;
    padding: 0px;
    flex: none;
}

.comm-info-slider-open .utility-button-image {
    width: 45px;
}

.poll-fail-commentator-status {
    position: absolute;
    width: 22px;
    height: 22px;
    bottom: -3px;
    right: -4px;
}

.commentator-green-status {
    background-color: #39b54a;
}

.commentator-yellow-status {
    background-color: rgb(255, 255, 0);
}

.commentator-orange-status {
    background-color: orange;
}

.commentator-red-status {
    background-color: red;
}

.commentator-grey-status {
    background-color: grey;
}

.commentator-gs-slider {
    width: 160px;
    position: relative;
}

.block-comm-container {
    margin-top: 10px;
}

.block-comm-btn {
    width: 65px;
}

.nunito-font {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
}

.commentator-section-msg {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 15px 0px;
}

.restart-in-cms,
.restart-audio-in-cms,
.refresh-in-cms {
    font-size: 28px;
    top: 4px;
}

.mixer-link-container {
    width: 56px;
    height: 100%;
    text-align: center;
    position: relative;
}

.comm-chat-message-notification-dot {
    background-color: red;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 0;
    right: 10%;
}

#producer-link-img {
    width: 37px;
    cursor: pointer;
}

#mixer-link-img {
    width: 37px;
    cursor: pointer;
}

.commentator-partition .restart-in-cms {
    margin-top: 10px;
    margin-left: -14px;
}

.commentator-partition .restart-audio-in-cms,
.commentator-partition .refresh-in-cms {
    margin-top: 10px;
    margin-left: -4px;
    cursor: pointer;
}

#game-sound-volume-label {
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    top: -17px;
}

.commentator-row-labels {
    word-wrap: break-word;
    width: 65px;
    text-align: center;
    vertical-align: top;
    font-size: 13px;
}

.commentator-row-labels-container {
    padding-top: 5px;
    text-align: right;
    padding-right: 20px;
}

.refresh-page-container {
    padding-left: 18px !important;
}

.controls-container-studio {
    background: #bebebeef;
    padding: 0 !important;
    position: relative;
}

.controls-container-studio .commentator-studio-chat-container {
    flex: 1 1 auto;
    height: auto;
    margin: 0 !important;
}

.controls-container-studio .commentator-studio-chat-container {
    color: white;
    display: flex;
    flex-direction: column;
}

.controls-container-studio .commentator-studio-chat-container .chat-content {
    flex: 1 0 auto;
}

.controls-container-studio .commentator-studio-chat-container .all-messages-container {
    height: calc(100% - 60px);
}

.controls-container-studio .team-volume-controls-container {
    flex: 1 0 auto;
    height: auto;
    margin: 0 !important;
}

.disable-mixer-btn {
    cursor: not-allowed !important;
}

.audio-tools-controls-container {
    background-color: #323231;
    width: 40%;
    padding-top: 10px;
}

.audio-mode-btn-container {
    display: inline-block;
    flex-shrink: 0;
    border-right: 1px solid #959595;
    width: 10%;
    color: #cccaca;
    text-align: center;
    cursor: pointer;
    background-color: #323231;
    overflow: hidden;
}

.audio-mode-btn-active {
    background-color: #efa622;
    color: white;
}

.audio-mode-btn {
    padding-top: 12px;
    width: 100%;
    height: calc(100% / 3);
}

.audio-mode-btn-with-r128-filter {
    padding-top: 5px;
    padding-bottom: 7px;
    height: calc((100% - 30px) / 4);
}

.audio-mode-btn-loudnorm {
    height: calc(((100% - 30px) / 4) + 30px);
}

.audio-mode-img {
    display: block;
    margin: 5px auto;
    width: 60%;
    max-width: 85px;
}

.audio-mode-img-with-r128-filter {
    max-width: 60px;
}

.audio-mode-label {
    font-size: 17px;
}
