.team-volume-controls-container {
    background-color: #323231;
    display: inline-block;
    height: 350px;
    flex: 1 1 calc(50% - 132px);
}

.flex-parent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.vu-canvas {
    width: 100%;
    height: 100%;
}

.volume-bar-block {
    height: 100%;
    width: 100%;
}

.volume-thumbnail {
    border-radius: 30px;
    width: 37px;
    height: 37px;
    margin: 0 auto;
    display: block;
}

.volume-block-container-extra-wide .volume-thumbnail,
.volume-fullscreen-controls .volume-thumbnail {
    width: 50px;
    height: 50px;
}

.volume-thumbnail-holder {
    position: relative;
    margin-top: 10px;
}

.game-sound-volume-img-container {
    text-align: center;
}

.volume-img-holder {
    margin-top: 10px;
    height: 37px;
    width: 37px;
}

.audio-mode-btn-container + .team-volume-controls-container .volume-img-holder,
.volume-fullscreen-controls .volume-img-holder,
.volume-slider-container-extra-wide + .game-sound-volume-img-container .volume-img-holder {
    width: 50px;
    height: 50px;
}

.volume-mute-icon {
    position: absolute;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
}

.game-sound-mute-icon {
    width: 19px;
    height: 15px;
    left: 24px;
}

.volume-fullscreen-controls .game-sound-mute-icon {
    width: 26px;
    left: 26px;
    height: 20px;
}

.audio-loudnorm-controls-container,
.audio-equaliser-controls-container,
.audio-compressor-controls-container {
    background-color: #323231;
    display: inline-block;
    width: 100%;
}

.audio-loudnorm-controls-container {
    padding-top: 40px;
}

.volume-block-container {
    display: inline-block;
    width: 66px;
    padding: 15px 10px;
    height: 100%;
    position: relative;
    margin: auto;
}

.volume-block-container-extra-wide {
    width: 85px;
}

.member-select-audio-compression {
    margin-top: 5px;
    margin-left: 25px;
    margin-bottom: 20px;
}

.audio-equaliser-controls {
    display: block;
    width: 100%;
    height: 330px;
}

.audio-compressor-controls {
    display: block;
    width: 100%;
    margin-bottom: 25px;
}

.audio-loudnorm-controls {
    display: block;
    width: 100%;
    padding: 5px;
    padding-bottom: 15px;
}

.audio-loudnorm-label,
.audio-compressor-label {
    display: inline-block;
    color: rgb(94, 94, 94);
    font-size: 18px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    width: 25%;
    text-align: right;
}

.audio-loudnorm-label {
    width: 30%;
    text-align: left;
    padding-left: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    vertical-align: middle;
}

.audio-compressor-mode .audio-compressor-label,
.audio-compressor-characteristic .audio-compressor-label {
    font-size: 14px;
}

.audio-compressor-mode,
.audio-compressor-characteristic {
    display: inline-block;
    width: 75%;
}

.audio-loudnorm-label-value,
.audio-compressor-ratio-value,
.audio-compressor-threshold-value {
    display: inline-block;
    color: #faa61a;
    font-size: 16px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    width: 14%;
    text-align: right;
    margin-left: 10px;
}

.audio-loudnorm-label-value {
    text-align: left;
    padding-left: 10px;
    margin-left: 0;
    width: 20%;
}

.audio-compressor-ratio-value-disabled {
    color: rgb(94, 94, 94);
}

.audio-equaliser-slider-container {
    width: calc(100% / 12);
    display: inline-block;
    height: 100%;
}

.audio-equaliser-slider-container .rangeslider__label-item::before {
    width: 5px !important;
    left: -10px !important;
    background-color: rgb(94, 94, 94) !important;
}

.audio-equaliser-slider-container li[data-value="0"].rangeslider__label-item::before {
    width: 10px !important;
    left: -13px !important;
}

.audio-equaliser-slider-container .rangeslider {
    border-radius: 5px;
    background-color: #292f37;
}

.audio-equaliser-controls-container .rangeslider__labels {
    top: 11px;
}

.audio-equaliser-controls-container .rangeslider__handle {
    outline: none !important;
    border: none;
    left: 0;
    width: 10px;
    height: 25px;
    border-radius: 2px;
    background-image: linear-gradient(to top, #ffa200, #ff7e00);
}

.audio-equaliser-slider-container .rangeslider__fill {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #292f37;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.slider-double-width {
    width: calc((100% / 12) * 2);
    margin-bottom: 2px;
}

.slider-double-width .rangeslider {
    width: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 25px;
    height: 74%;
}

.slider-double-width .rangeslider__labels {
    left: -20px;
}

.slider-double-width .rangeslider__label-item::before {
    left: 61px !important;
}

.slider-double-width li[data-value="0"].rangeslider__label-item::before {
    width: 15px !important;
    left: 42px !important;
}
.slider-double-width li[data-value="25"].rangeslider__label-item::before,
.slider-double-width li[data-value="-25"].rangeslider__label-item::before {
    width: 15px !important;
    left: 58px !important;
}

.slider-double-width .rangeslider__label-item {
    left: -70px;
}

.slider-double-width li[data-value="0"].rangeslider__label-item {
    left: -59px;
}
.slider-double-width li[data-value="25"].rangeslider__label-item,
.slider-double-width li[data-value="-25"].rangeslider__label-item {
    left: -75px;
}

.slider-double-width .rangeslider__handle {
    visibility: hidden;
}

.slider-double-width .rangeslider__fill {
    background-color: unset;
}

.audio-equaliser-frequency-range {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: #faa61a;
}

.audio-equaliser-band-value {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 12px;
    color: rgb(94, 94, 94);
}

.audio-loudnorm-slider,
.audio-compressor-slider {
    width: 50%;
    display: inline-block;
    margin: 0;
    margin-left: 20px;
}

.audio-loudnorm-slider {
    width: 44%;
}

.audio-compressor-slider-disabled .rangeslider__handle {
    cursor: not-allowed;
}

.audio-compressor-checkbox {
    margin-left: 20px !important;
    margin-top: -2px !important;
}

.audio-loudnorm-checkbox input[type="radio"],
.audio-loudnorm-checkbox input[type="radio"]:hover,
.audio-compressor-checkbox input[type="radio"],
.audio-compressor-checkbox input[type="radio"]:hover {
    background: rgb(0, 255, 221) !important;
    background-color: rgb(0, 255, 221) !important;
    color: rgb(0, 255, 221) !important;
}

.audio-loudnorm-slider .rangeslider__labels,
.audio-equaliser-label-slider .rangeslider__labels,
.audio-compressor-slider .rangeslider__labels {
    color: rgb(94, 94, 94);
}

.audio-loudnorm-slider .rangeslider__handle,
.audio-equaliser-label-slider .rangeslider__handle,
.audio-compressor-slider .rangeslider__handle {
    background: #323231;
    border: 3px solid #faa61a;
    width: 20px;
    height: 20px;
    outline: none !important;
}

.audio-loudnorm-controls .rangeslider-horizontal,
.audio-compressor-controls .rangeslider-horizontal {
    height: 2px !important;
}

.audio-loudnorm-controls .rangeslider-horizontal .rangeslider__handle:after,
.audio-compressor-controls .rangeslider-horizontal .rangeslider__handle:after {
    content: none;
}

.audio-loudnorm-controls .rangeslider,
.audio-loudnorm-controls .rangeslider-horizontal .rangeslider__fill,
.audio-compressor-controls .rangeslider,
.audio-compressor-controls .rangeslider-horizontal .rangeslider__fill {
    background-color: rgb(94, 94, 94);
}

.audio-loudnorm-controls .rangeslider {
    vertical-align: text-top;
}

.audio-equaliser-controls-container #choose-team-member-label,
.audio-compressor-controls-container #choose-team-member-label {
    color: white;
}

.audio-compression-graph-container {
    background-color: #323231;
    padding: 10px;
    flex-grow: 1;
    overflow: hidden;
    z-index: 1;
    width: 48%;
    height: 100%;
}

.volume-bar-container {
    height: calc(100% - 50px);
    text-align: center;
}

.audio-mode-btn-container + .team-volume-controls-container .volume-bar-container,
.volume-fullscreen-controls .volume-bar-container {
    height: calc(100% - 60px);
}

.volume-control-transparent {
    opacity: 0.3;
}

.commentator-volume-slider-muted .rangeslider__fill {
    background-color: grey !important;
    border-bottom: 10px solid grey !important;
}

.volume-control-faded {
    opacity: 0.7;
}

.grey-vertical-seperator {
    display: inline-block;
    border-right: 1px solid grey;
    opacity: 0.5;
    height: 320px;
    margin: 15px 0;
}

.volume-slider-container {
    height: calc(100% - 80px);
    position: absolute;
    text-align: center;
    top: 15px;
    left: 27px;
    padding: 6% 0;
}

.volume-slider-container-extra-wide {
    left: 37px !important;
}

.volume-fullscreen-controls .monitor-container .volume-slider-container {
    left: 30px !important;
}

.volume-fullscreen-controls .monitor-container .new-level-design {
    left: 11px !important;
}

.lock-mixer-section-glyph {
    cursor: pointer;
}

.commentator-volume-slider > .rangeslider__handle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1px 1px #333;
}

.commentator-volume-slider > .rangeslider__label {
    width: 0px;
}

.audio-equaliser-slider {
    height: 75%;
}

.commentator-volume-slider > .rangeslider__labels,
.audio-equaliser-slider > .rangeslider__labels {
    width: 0px;
}

.commentator-volume-slider > .rangeslider__handle:focus {
    outline: none !important;
}

.commentator-volume-slider > .rangeslider__fill {
    background-color: #f7ad1b;
    border-bottom: 10px solid #f7ad1b;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.commentator-volume-slider {
    background: black;
    border-radius: 20px;
    height: 100%;
    margin: 0 auto;
}

.commentator-volume-slider.tw-cursor-not-allowed > .rangeslider__handle {
    cursor: not-allowed;
}

.volume-fullscreen-controls {
    position: absolute;
    left: 10px;
    bottom: 13px;
    z-index: 0; /* Start a new stacking context for tidyness. This means, for this element's children, their z-index is relative to this element. */
    background: transparent;
    width: 500px;
}

.volume-fullscreen-controls .grey-vertical-seperator {
    height: 50px;
    margin-top: 285px;
}

.user-mic-icon {
    position: absolute;
    bottom: 0px;
    right: -6px;
    height: 20px;
    border-radius: 20px;
}

.user-off-air-icon {
    position: absolute;
    bottom: 0px;
    left: -4px;
    height: 22px;
    border-radius: 20px;
}

.volume-block-container-extra-wide .user-mic-icon {
    right: 8px;
}

.volume-block-container-extra-wide .user-off-air-icon {
    left: 6px;
}

.opacity-75 {
    opacity: 0.75;
}

.monitor-container {
    text-align: center;
    margin: 5px;
    border: 1px solid lightgrey;
}

.mixer-container {
    margin: 5px;
    border: 1px solid #959595;
    width: 100%;
    position: relative;
}

.lock-mixer-section-img {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: -9px;
    height: 30px;
    width: 30px;
}

.mixer-container-locked {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 30;
    width: 100%;
    height: calc(100% - 50px);
    cursor: not-allowed;
}

.volume-fullscreen-controls .mixer-container-locked {
    position: absolute;
    bottom: -13px;
    z-index: 3;
    width: 100%;
    height: 100px;
}

.volume-fullscreen-controls .lock-mixer-section-img {
    cursor: pointer;
    position: absolute;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    z-index: 4;
    right: unset;
    bottom: 0px;
}

.volume-fullscreen-controls .lock-mixer-section-img-locked {
    position: unset;
    height: 40px;
    right: 0px;
    width: 40px;
    margin-left: 0px;
}

.mixer-section-container-block {
    z-index: 4;
    bottom: 30px;
    position: absolute;
    width: 100%;
}

.mixer-container .grey-vertical-seperator,
.monitor-container .grey-vertical-seperator {
    height: 100%;
    margin: 0;
}

.monitor-container .volume-block-container {
    margin: auto;
    margin-bottom: -6px;
}

.team-member-section-container {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid grey;
}

.team-member-new-level-meters-containers {
    height: calc(100% - 39px);
}

.team-member-section-container-using-audio-metering {
    background: repeating-linear-gradient(
        transparent 0 44px,
        black 44px calc(100% - 48px),
        transparent calc(100% - 48px) 100%
    );
    justify-content: space-between;
}

.mixer-section-label-container {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 14px;
    height: 16px;
    margin-top: 10px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
}

.monitor-container .mixer-section-label-container {
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid #959595;
}

.volume-fullscreen-controls .monitor-container .mixer-section-label-container {
    border: none;
    padding-top: 3px;
}

.include-side-audio-control-panel .mixer-container {
    min-width: calc(100% - 20px);
    margin: 10px;
}

.mixer-container .mixer-section-label-container {
    margin-top: 15px;
}

.volume-fullscreen-controls #monitor-members-section {
    margin-top: 28px;
}

.volume-fullscreen-controls #monitor-section-label-container {
    margin-top: 5px;
}

.volume-fullscreen-controls .team-member-section-container,
.volume-fullscreen-controls .volume-block-container {
    border: none;
}

.volume-fullscreen-controls .team-member-section-container + #mixer-section-label-container,
.volume-fullscreen-controls .volume-block-container + #monitor-section-label-container {
    font-size: 13px;
    margin: 0;
    margin-top: 5px;
}

.volume-fullscreen-controls .mixer-container,
.volume-fullscreen-controls .monitor-container {
    border: none;
    margin-bottom: 0;
}

.volume-fullscreen-controls .mixer-container .team-member-section-container {
    height: calc(100% - 17px);
    padding-bottom: 0;
}

.volume-fullscreen-controls .monitor-container .volume-block-container {
    height: calc(100% - 15px);
    padding-bottom: 5px;
}

.volume-fullscreen-controls .mixer-container .volume-block-container {
    padding-bottom: 5px;
}
