/* 
This allows us to size the sliders according to the ancestor 
that overflows. This keeps there positioning constant irrespective
of the scrollbar existing or not
*/
.studio-multi-channel-sliders-container {
    container: studio-audio / size;
}

.studio-multi-channel-sliders {
    height: calc(100cqh - 16px) !important;
}
