.spalk-date-picker-popper .react-datepicker__month {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
}

.spalk-date-picker-popper .react-datepicker__day:hover {
    border-radius: 30px;
}

.spalk-date-picker-popper .react-datepicker__day--keyboard-selected {
    border-radius: 30px;
    background-color: #2979ff;
}

.spalk-date-picker-popper .react-datepicker__triangle {
    display: none;
}

.spalk-date-picker-popper .react-datepicker__day--outside-month {
    color: #d2d2d6;
}

.spalk-date-picker-popper .react-datepicker {
    border-radius: 0px !important;
    border: 1px solid black;
}

.spalk-date-picker-popper {
    margin-top: 20px !important;
}

.spalk-date-picker-popper .react-datepicker__header {
    background-color: unset;
    border-bottom: unset;
}

.spalk-date-picker-popper .react-datepicker__day-name {
    text-transform: uppercase;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    font-size: 8px;
    font-weight: 800;
}

#spalk-date-picker {
    border-radius: 5px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    border: 1px solid #b4b4bb;
    height: 100%;
    font-size: 14px;
    line-height: 1.5px;
    padding: 4px 7px;
    text-align: center;
}

.spalk-date-picker-container .react-datepicker__input-container,
.spalk-date-picker-container .react-datepicker-wrapper {
    height: 100%;
}
