.audio-input-selector {
    color: black;
}

.audio-input-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.audio-input-label-container {
    margin-top: 5px;
    margin-bottom: 5px;
}

.audio-input-label {
    font-size: 15px;
}
