#remove-event {
    cursor: pointer;
    color: red;
}

.oops-message-styling {
    width: 100%;
}

#stop-device-button-container {
    text-align: center;
}

#stop-device-button {
    cursor: pointer;
}

.add-object-to-table {
    float: right;
    font-size: 18px;
    color: rgb(102, 102, 102);
}

.cms-table-header-label {
    font-size: 16px;
}

.spinner {
    height: 20px;
    margin: auto;
}

#mod-title {
    margin-left: 0px;
    float: left;
}

.modal-cross {
    float: right;
}

.create-organisation-field,
.register-device-field,
.add-version-field {
    margin: 10px 20px;
}

.create-organisation-label,
.register-device-label,
.add-version-label {
    margin-bottom: 5px;
}

.create-organisation-input,
.register-device-input,
.add-version-input {
    max-width: 100% !important;
}

.member-select-input {
    display: inline-block;
    width: 150px;
    vertical-align: top;
    margin-top: 6px;
    margin-right: 5px;
    color: black;
}

#add-version-btn {
    float: right;
}

.cms-route {
    padding: 8px 15px;
    margin-bottom: 20px;
}

.cms-content-body {
    background-color: white;
}

.transparent-body {
    background-color: transparent !important;
}

.cms-edit-body {
    background-color: white;
    padding: 15px;
}

.cms-table-header {
    color: #fff;
    background-color: #f7a22f;
    border-color: #f7a22f;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.cms-table-body {
    padding: 15px;
    background-color: white;
}

.active-comms-icon {
    color: orange;
    height: 50px;
    cursor: pointer;
}

.team-kurento-pipelines {
    font-size: 18px;
    text-align: left;
}

.team-kurento-pipelines-options {
    display: inline-block;
    font-size: 16px;
    width: 70%;
}

.team-pipelines-label {
    display: inline-block;
    margin: 10px;
    width: 25%;
    text-align: left;
    padding-left: 5px;
}

.team-kurento-pipelines-options .team-pipelines-label {
    font-weight: normal;
    width: calc((70% - 75px) / 5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding-bottom: 3px;
}

.remove-team-pipeline-action {
    cursor: pointer;
    height: 15px;
    vertical-align: text-top;
}

.modal-btn {
    display: inline-block;
    border: 1px solid silver;
    border-radius: 18px;
    text-align: center;
    line-height: 35px;
    font-weight: 400;
    font-size: 13px;
    margin-left: 10px;
    color: #9e9e9e;
    cursor: pointer;
    margin: 0;
    padding: 0;
    height: 35px;
}

.close-btn {
    width: 90px;
}

.save-btn {
    width: 110px;
}

.add-btn {
    width: 75px;
    float: right;
    margin-top: 5px;
}

.modal-btn:focus,
.modal-btn:active:hover {
    outline: none;
}

.modal-error {
    width: 100%;
}

.cms-edit-user-problematic-ff {
    background-color: #ff9a9a;
}

.modal-input-wrap {
    width: 80%;
    margin: auto;
    text-align: right;
}

.rtmp-styling {
    width: 100%;
    display: inline-block;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer:focus,
.ReactTable .rt-thead .rt-th.-cursor-pointer:active:hover {
    outline: none;
}

.cms-half-width {
    width: 50%;
    display: inline-block;
}

.cms-quarter-width {
    width: 25%;
    display: inline-block;
}

.cms-auto-width {
    width: auto;
    display: inline-block;
    margin-right: 5px;
}

.cms-max-width {
    max-width: 550px;
}

#cms-number-input-style {
    width: 240px;
    display: inline-block;
}

#cms-checkbox-input-style {
    width: 280px;
    margin-left: 20px;
    padding-top: 11px;
    display: inline-block;
}

.cms-event-img {
    display: block;
    height: auto;
    max-height: 225px;
    max-width: 100%;
    margin: auto;
}

#output-target-button {
    height: 28px;
    padding: 0px;
    margin-left: 5px;
    width: 25%;
}

.output-anywhere-input {
    width: calc(94% - 12rem);
}

.status-indicator {
    margin: 5px 8px;
    padding: 3px;
    border: 1px gray solid;
    font-size: 9px;
    display: block;
    text-align: center;
}

.cms-table-rtmp-bumpers .status-indicator {
    margin-top: 8px;
}

.cms-icon-wrapper {
    margin-bottom: 0;
}

.thumbnail-label-container {
    margin-top: 10px;
    text-align: center;
}

.thumbnail-label-glyph {
    width: 10px;
    cursor: pointer;
    margin-right: 10px;
}

#rtmp-last-update-text {
    font-size: 10px;
    margin-left: 20px;
}

.media-div {
    display: inline-block;
    margin: 2.5%;
    width: 20%;
}

.media-image-width {
    width: 100%;
}

#update-device-version {
    padding-left: 15px;
    cursor: pointer;
}

.flag-img {
    height: 25px;
    cursor: pointer;
    margin: 5px;
}

.flag-add-user-input {
    border: 1px solid #555555;
    outline: none !important;
    height: 35px;
    border-radius: 5px;
    padding: 5px;
    width: 85%;
}

.show-options-glyph,
#feature-flags-info-icon {
    margin-left: 10px;
    cursor: pointer;
}

.flag-add-user-dropdown {
    max-height: 40vh;
    overflow: hidden;
    overflow-y: auto;
}

.edit-user-label {
    display: inline-block;
    width: 45%;
}

.edit-users-flags-label {
    display: inline-block;
    width: 45%;
}

.edit-user-attributes {
    margin-top: 10px !important;
    max-width: 900px;
}

.cms-edit-user-checkbox {
    margin-right: 15px !important;
    display: inline-block;
    margin-left: 1px !important;
}

.cms-edit-user-field {
    display: inline-block;
    width: 120px;
}

.edit-user-number-feild-label {
    margin-top: 15px;
    display: inline-block;
    width: 275px;
}

.flag-add-user-dropdown::-webkit-scrollbar {
    width: 5px;
}

.flag-add-user-dropdown::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
}

.feature-flags-cell {
    padding-top: 17px !important;
}

.bumpers-cell {
    padding-top: 15px !important;
    text-align: center;
}

.feature-flags-cell-center-align {
    text-align: center;
}

.filter-organisations-cms,
.filter-vod-muxing-cms,
.filter-users-cms {
    display: inline-block;
    margin-left: 20px;
}

.filter-organisations-cms-input,
.filter-vod-muxing-cms-input,
.filter-users-cms-input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    padding-left: 10px;
    outline: none !important;
}

.cms-has-lock {
    text-align: center !important;
    display: block;
}

.active-comms-dd {
    padding: 10px;
    background-color: #dbdada;
}

.cms-content-body-col {
    margin: auto;
    margin-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
}

#breadcrumb-link {
    color: black;
}

#breadcrumb-glyph {
    padding-left: 15px;
    color: #f7a22f;
}

.fetch-text-color {
    color: rgb(102, 102, 102);
}

.inline-block {
    display: inline-block;
}

.spinner-img {
    height: 25px;
    margin-left: 10px;
}

#cms-event-link-img-monitor {
    width: 32px;
}

#cms-event-link-img-commentate {
    width: 23px;
}

.active-comms-table .rt-expander {
    z-index: -10;
}

.delete-schedule-icon {
    cursor: pointer;
    color: red;
    padding: 5px;
    font-size: 20px;
}

#rtmp-sched-table-name {
    font-size: 16px;
}

.sfu-configs-container {
    text-align: right;
    display: inline-block;
    width: 100%;
    position: relative;
}

.rtmp-stream-btn-container {
    text-align: right;
    display: inline-block;
    width: 50%;
}

.rtmp-stream-input-container {
    margin-left: 10px;
    display: inline-block;
    width: calc(100% - 50% - 55px);
}

#rtmp-streams-filter-input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    padding-left: 10px;
    outline: none !important;
}

#fetch-tracks-btn {
    margin-left: 10px;
}

.cms-table-bumpers .rt-td {
    overflow: unset !important;
}

.bumper-destination-column-container {
    text-align: center;
}

#add-version-btn {
    cursor: pointer;
}

#remove-bumper-button {
    margin-top: 10px;
    cursor: pointer;
}

.bumper-btn {
    right: 40px;
    position: absolute;
}

.bumpers-destination-input {
    width: 90%;
    height: 38px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    outline: none;
}

.bumper-destination-input {
    width: 100%;
}

.bumper-loopinput-checkbox {
    text-align: center;
    margin-top: 7px;
}

.bumper-loop-checkbox {
    margin: 0 !important;
}

.action-cell-wrapper {
    padding-left: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.register-device-cognito-info {
    text-align: center;
}

.deployment-enabed-checkbox {
    text-align: center;
}

.edit-organisation-container {
    position: relative;
    width: calc(100% - 80px);
    margin-left: 85px;
    min-height: 500px;
    background-color: rgb(248, 248, 248);
    padding-bottom: 70px;
}

.edit-organisation-header {
    background-color: #f7a22f;
    padding-top: 5px;
    padding-left: 5px;
    margin-bottom: 15px;
}

.edit-organisation-member {
    display: block;
    padding-left: 25px;
}

.edit-organisation-member-username {
    display: inline-block;
    width: 30%;
    padding-right: 10px;
}

.edit-organisation-label-header {
    font-size: 16px;
    padding: 10px;
}

.edit-org-cms-flag-table {
    width: 70%;
    margin-left: 15px;
    margin-bottom: 30px;
}

.edit-organisation-label {
    display: inline-block;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.edit-flag-form-control {
    margin-top: 5px;
    margin-bottom: 5px;
}

.organisation-new-member-input,
.organisation-user-role-input {
    display: inline-block;
    width: 200px;
    padding: 5px;
    margin-bottom: 15px;
    margin-right: 10px;
}

.organisation-new-member-input {
    width: 400px;
}

.organisation-user-role-input {
    margin: 0;
}

.organisation-new-member-input .css-15k3avv,
.organisation-user-role-input .css-15k3avv {
    height: unset !important;
    overflow-y: unset !important;
    margin-top: -5px;
}

.edit-organisation-btn-container {
    padding-top: 15px;
    margin-left: 15px;
    width: 100px;
    padding-bottom: 20px;
}

.edit-organisation-glyph {
    vertical-align: top;
    margin-top: 8px;
    font-size: 16px;
    color: red;
    cursor: pointer;
}

.organisation-user-role-text-input {
    width: 200px;
    height: 40px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 10px;
    padding-left: 10px;
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #626060;
    outline: none !important;
    margin-bottom: 15px;
    margin-right: 10px;
}

#add-member-icon {
    height: 30px;
    width: 30px;
    margin: 5px;
    cursor: pointer;
}

.cms-live-dash-btn {
    position: absolute;
    right: 50px;
}

.workflow-type-label {
    margin-top: 15px;
}

@media screen and (max-width: 768px) {
    #cms-icon-wrapper {
        margin-left: 0;
    }

    .cms-half-width {
        width: 100%;
    }

    .cms-quarter-width {
        width: 100%;
    }

    #cms-number-input-style {
        width: 100%;
    }

    #cms-edit-days-input,
    #cms-edit-months-input {
        width: 25% !important;
    }

    #cms-edit-years-input {
        width: 40% !important;
    }

    #cms-checkbox-input-style {
        margin-left: 0;
        width: 100%;
    }

    .cms-edit-btn {
        float: none;
    }

    #language-form-group {
        width: 100% !important;
        max-width: 550px;
    }

    .form-body-col {
        width: 100%;
    }
}

.ff-problem-flag-col {
    vertical-align: top;
    width: 300px;
}

.cms-problem-ff {
    margin-left: 5px;
}

.cms-ff-problematic-flag-row {
    border: solid 1px black;
    padding: 10px;
}

.cms-ff-problematic-headers {
    font-weight: bold;
    font-size: 20px;
}

.cms-problem-ff-title {
    font-weight: bold;
    font-size: 25px;
}

.cms-ff-problem-flags-container {
    border: 3px solid black;
}

#vod-mux-tasks-search-image {
    font-size: 22px;
    top: 5px;
    left: 5px;
}

/* ------------------------------------------ STREAMS ------------------------------------------ */
.escape-hatch-parent-body {
    margin-top: 20px;
    padding-right: 40px;
}

.escape-hatch-content-body {
    background-color: white;
}

.escape-hatch-table-header {
    color: #fff;
    background-color: #f7a22f;
    border-color: #f7a22f;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.escape-hatch-table-body {
    padding: 15px;
    background-color: white;
}

.escape-hatch-modal-input {
    width: 78%;
    background-color: #efefef;
    border-radius: 5px;
    margin: 5px 0;
    height: 35px;
    border: 1px solid gray;
    padding: 0 10px;
}

.add-rtmp-stream {
    float: right;
    font-size: 18px;
    color: rgb(102, 102, 102);
    cursor: pointer;
}

#rtmp-output-streams-icon,
#rtmp-ingest-streams-icon,
.kurento-video-removal-icon {
    width: 30px;
    cursor: pointer;
}

.rtmp-output-streams-dd {
    background-color: rgb(197, 197, 197);
    text-align: center;
    padding: 0 10px;
}

.ll-output-stream-label {
    margin-top: 10px;
    width: calc((100% - 140px) / 6);
    text-align: left;
}

.ll-ingest-stream-download-space,
.ll-output-stream-download-space {
    margin-top: 10px;
    width: 80px;
}

.ll-ingest-stream-label-width-7,
.ll-ingest-stream-label-double-width-7,
.ll-ingest-stream-label-width-8,
.ll-ingest-stream-label-double-width-8,
.ll-ingest-stream-label-width-9,
.ll-ingest-stream-label-double-width-9 {
    margin-top: 10px;
    text-align: left;
}

.ll-ingest-stream-info-width-7,
.ll-ingest-stream-info-double-width-7,
.ll-ingest-stream-info-width-8,
.ll-ingest-stream-info-double-width-8,
.ll-ingest-stream-info-width-9,
.ll-ingest-stream-info-double-width-9 {
    margin-bottom: 10px;
    border: none;
    background-color: transparent;
    overflow: hidden;
}

.ll-ingest-stream-info-width-7,
.ll-ingest-stream-label-width-7 {
    width: calc((100% - 80px) / 7);
}

.ll-ingest-stream-info-double-width-7,
.ll-ingest-stream-label-double-width-7 {
    width: calc(((100% - 80px) / 7) * 2);
}

.ll-ingest-stream-info-width-8,
.ll-ingest-stream-label-width-8 {
    width: calc((100% - 80px) / 8);
}

.ll-ingest-stream-info-double-width-8,
.ll-ingest-stream-label-double-width-8 {
    width: calc(((100% - 80px) / 8) * 2);
}

.ll-ingest-stream-info-width-9,
.ll-ingest-stream-label-width-9 {
    width: calc((100% - 80px) / 9);
}

.ll-ingest-stream-info-double-width-9,
.ll-ingest-stream-label-double-width-9 {
    width: calc(((100% - 80px) / 9) * 2);
}

.ll-output-stream-action-label {
    margin-top: 10px;
    width: 60px;
    text-align: left;
}

.ll-ingest-stream-download {
    margin-bottom: 10px;
}

.ll-output-stream-info {
    width: calc((100% - 140px) / 6);
    margin-bottom: 10px;
    padding-right: 10px;
    border: none;
    background-color: transparent;
}

.action-glyph {
    display: inline-block;
    cursor: pointer;
    text-align: left;
    padding-left: 15px;
    width: 60px;
}

.action-btn {
    width: 80px;
    display: inline-block;
    cursor: pointer;
    text-align: left;
}

#modal-title {
    float: left;
    margin-left: 0px;
}

.start-btn {
    width: 100px;
}

.start-btn:focus,
.start-btn:active:focus {
    outline: none;
}

.escape-hatch-links {
    text-decoration: none !important;
    color: rgb(51, 51, 51);
    cursor: pointer;
}

.escape-hatch-link-label {
    font-size: 14px;
}

.errorMessage {
    color: red;
    font-size: 10px;
}

.cross {
    cursor: pointer;
    float: right;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer:focus,
.rt-resizable-header-content:focus {
    outline: none !important;
}

#loading-screen {
    text-align: center;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.escape-hatch-logout-container {
    display: inline-block;
    width: 50%;
    text-align: right;
    padding-bottom: 20px;
    padding-right: 10px;
}

.escape-hatch-fetch-container {
    display: inline-block;
    width: 50%;
    text-align: left;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-left: -25px;
}

.escape-hatch-btn {
    background-color: #f7a22f;
    display: inline-block;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.logout-msg-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    -ms-flex-line-pack: center;
    align-content: center;
    padding-top: 150px;
    padding-bottom: 550px;
    max-width: 400px;
}

.link-to-all-streams {
    color: white !important;
    text-decoration: none !important;
}

.request-download-btn {
    font-size: 24px;
    padding: 0;
    padding-top: 1px;
    margin-left: 15px;
    margin-top: -3px;
    width: 40px;
    min-width: 38px;
    border-radius: 30px;
    text-align: center;
    color: white;
    font-weight: 700;
}
