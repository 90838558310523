.monitor-webrtc-container {
    width: 80%;
    margin: auto;
    position: relative;
}

#monitor-player-container .multiple-audio-tracks-select {
    right: 50px !important;
    bottom: 57px;
    width: 150px !important;
    height: 36px;
    visibility: hidden;
}

#monitor-player-container div[role="option"] {
    outline: "none" !important;
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    color: black;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid rgb(175, 175, 175);
    border-radius: 0;
    padding: 5px 12px;
    box-shadow: none;
    min-height: 20px;
    margin-bottom: 0px;
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 27px !important;
    background-color: whitesmoke;
}

#monitor-player-container .multiple-audio-tracks-select .css-1hwfws3 {
    border-top: none;
}

#monitor-player-container:hover .multiple-audio-tracks-select {
    visibility: visible;
}

.monitor-team-output-container {
    position: relative;
}

#monitor-team-output-icon {
    position: absolute;
    top: -82px;
    right: 25px;
    color: white;
    visibility: hidden;
}

#monitor-player-container:hover #monitor-team-output-icon {
    visibility: visible;
}

.audio-track-output-btn-container {
    text-align: center;
}

.audio-track-output-btn-label {
    display: block;
    margin-top: 10px;
    font-size: 20px;
}

.video-quality-warning-msg {
    position: absolute;
    bottom: 10%;
    left: 50%;
    vertical-align: bottom;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 2vh;
    padding: 32px;
    border-radius: 0px;
    z-index: 2;
    font-weight: bold;
    transform: translate(-50%, 0);
}
