.spalk-time-picker-popup .rc-time-picker-panel-select-option-selected {
    border-radius: 30px;
    width: 25px !important;
    margin: 0 auto;
    background-color: #2979ff !important;
    color: #fff;
    font-weight: unset !important;
}

.spalk-time-picker-popup .rc-time-picker-panel-input {
    border-radius: 4px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    border: 1px solid #b4b4bb;
    font-size: unset;
    height: 100%;
    font-weight: 400;
    color: #333333;
}

.spalk-time-picker .rc-time-picker-input {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    border: 1px solid #b4b4bb;
    font-size: unset;
    height: 100%;
    font-weight: 400;
    color: #333333;
}

.spalk-time-picker-popup .rc-time-picker-panel-select li {
    padding-left: 0px !important;
    text-align: center !important;
    font-weight: unset !important;
}

.spalk-time-picker-popup .rc-time-picker-panel-select li:hover {
    border-radius: 30px;
    width: 25px !important;
    margin: 0 auto !important;
}

.spalk-time-picker-popup .rc-time-picker-panel-select {
    width: 50% !important;
    overflow-y: scroll;
}

.spalk-time-picker-popup .rc-time-picker-panel-select:last-child ul {
    border-left: 1px solid #e2e8f0;
}

.spalk-time-picker-popup .rc-time-picker-panel-select:first-child {
    border-left: 1px solid black;
    border-right: unset;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.spalk-time-picker-popup .rc-time-picker-panel-select:last-child {
    border-left: unset;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.spalk-time-picker-popup .rc-time-picker-panel-input-wrap {
    padding: 0px !important;
    border-bottom: unset !important;
    margin-bottom: 10px;
    height: 100%;
}

.spalk-time-picker-popup .rc-time-picker-panel-inner {
    background-color: transparent !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    border: unset !important;
    top: 3px !important;
    width: 100%;
    height: 100%;
    font-size: unset;
}

.spalk-time-picker-popup .rc-time-picker-panel-input-invalid {
    border-color: #ef4444;
}

.rc-time-picker-panel-narrow {
    width: 100% !important;
    max-width: 100% !important;
}
