body {
    background-color: #f5f5f5;
}

.parentBody {
    padding-top: 30px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
}

#upcomingEventsComponent {
    width: 25%;
    float: left;
    background-color: white;
}

.userSection {
    background-color: white;
    height: 90px;
    padding-top: 20px;
    margin: 0;
}

.user-image {
    display: inline;
    width: 50px;
    height: 50px;
    margin-left: 20px;
}

.upload-forms {
    z-index: 20;
}

.upload-date-form {
    z-index: 21;
}

.fl-right {
    display: inline-block !important;
}

.oops-error-message {
    width: 100%;
}

#oopsMessageDisplayed {
    text-align: left;
}

.user-name {
    display: inline;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    padding-left: 3px;
}

.sectionOne {
    margin: 0;
    margin-top: 2px;
    background-color: white;
    padding-top: 19px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    z-index: 1;
}

.uploadStatement {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    margin-left: 22px;
    text-align: left;
}

.upload-link {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Nunito Sans", sans-serif;
}

.col-style {
    padding: 0;
    padding-top: 15px;
}

.control-label {
    text-align: left;
    margin-bottom: 0;
    padding-top: 7px;
    font-size: 14px;
    color: "#0f172a";
    font-family: "Nunito Sans", sans-serif;
    width: 100%;
}

.inputField {
    height: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
}

.error {
    padding-top: 10px;
    position: relative;
}

.start-time {
    padding-top: 15px;
}

.end-time {
    padding-top: 25px;
}

#startTime-field,
#endTime-field {
    font-size: 14px;
}

.privacy-setting-container {
    margin-top: -10px !important;
}

.privacy-setting-label {
    font-weight: 100;
}

.checkbox-error {
    margin-top: 5px;
    text-align: left;
}

.errorMessage {
    color: red;
    font-size: 10px;
    padding: 0;
    margin-top: -4px;
}

.upload-flex-row-center {
    display: flex;
    align-items: center;
}

.float-left {
    float: left;
}

.positionErrorMessage {
    position: absolute;
    top: -6px;
    left: 0px;
}

.upload-section {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.form-group {
    margin: 0;
}

.ButtonWrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.iconWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 240px;
    margin-top: 5px;
}

.icon {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    padding: 0;
}

.loading-spinner {
    display: block;
    height: 198px;
    width: 198px;
    margin: auto;
}

.loading-spinner-label {
    text-align: center;
}

.thumbnail-span {
    color: "#0f172a";
    font-family: "Nunito Sans", sans-serif;
}

.form-control {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    box-shadow: none;
}

.form-top-padding {
    padding-top: 20px;
}

#sport-field,
#server-location-field,
#ingest-config-field,
#device-field,
#persistent-endpoint-field {
    background-color: rgb(221, 221, 221);
    border-color: rgb(128, 128, 128);
    height: 40px;
}

#contact-link-no-devices {
    margin: 10px;
}

#server-location-field,
#ingest-config-field,
#device-field {
    width: 300px;
}

#server-location-field,
#ingest-config-field,
#device-field,
#persistent-endpoint-field {
    margin-bottom: 3px;
}

#save-endpoint {
    float: right;
    cursor: pointer;
    position: relative;
}

#add-new-persistent-input-btn {
    width: 90px;
    position: absolute;
    right: 0;
    top: -20px;
    float: right;
}

#input-label {
    margin-left: 5px;
}

#entitlement-arn-info-label,
#static-encryption-key-info-label {
    font-size: 11px;
    display: block;
}

#server-location-label,
#device-label {
    margin-top: 8px;
    font-weight: 700;
    color: black;
    margin-bottom: 4px;
}

#exit-persistent-input-modal {
    float: right;
}

#persistent-input-modal-loader {
    position: absolute;
    right: 110px;
}

.modal-body {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}

#server-location-info-icon,
#device-info-icon,
#use-rtmp-source-info-icon,
#use-hls-source-info-icon,
.ingest-source-info-icon,
#use-persistent-source-info-icon {
    margin-left: 10px;
    cursor: pointer;
    top: 1px;
}

#use-single-use-endpoint-field,
#use-persistent-endpoint-field,
#use-rtmp-ingest-field,
#use-media-connect-ingest-field,
#use-device-ingest-field,
#use-srt-ingest-field,
#rtmp-ingest-bitrate,
.upload-srt-fields {
    margin-left: 34px;
    margin-right: 10px;
    font-weight: 400;
}

.persistent-input-body-block {
    width: 55%;
    margin: auto;
    text-align: right;
    margin-top: 5px;
}

.latency-label {
    margin-top: 4px;
    vertical-align: top;
}

.persistent-input-body-region-select-block {
    width: 50%;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    margin-top: -10px;
}

.persistent-input-body-input {
    margin-left: 10px !important;
    font-weight: 400;
}

.persistent-input-body-latency-label {
    width: 52%;
    text-align: left;
    display: inline-block;
    box-shadow: none !important;
}

.persistent-input-body-latency-slider {
    width: 48%;
    display: inline-block;
    height: 12px;
}

#rtmp-ingest-bitrate,
.upload-srt-fields,
#device-ingest-internal-buffer-length {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    outline: none;
    padding: 2px;
    padding-left: 5px;
}

.event-latency-slider {
    width: 155px;
    margin-top: 12px;
    margin-left: 25px;
}

.copy-text-icon {
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
}

.rtmp-ingest {
    margin-top: -1px;
    width: 85%;
    margin: 0;
    display: inline-block;
}

.color-black {
    color: black;
}

.margin-top-2px {
    margin-top: 2px;
}

.react-date-picker__button__input input {
    width: 100%;
    height: 40px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    top: -10px !important;
}

.react-datepicker__input-container {
    width: 100%;
}

.upload-time-picker-label {
    padding-top: 8px;
}

.upload-time-picker {
    height: 40px;
    font-weight: 400;
}

.rc-time-picker-input {
    color: #333;
    height: 100%;
    border: none;
    width: 100%;
}

.rc-time-picker-panel ul {
    background: white;
}

.rc-time-picker-panel-select {
    overflow: hidden;
}

.rc-time-picker-panel-select:hover {
    overflow-y: scroll;
}

.rc-time-picker-panel-select-option-selected {
    background: #edfaff;
    color: #2db7f5;
}

.rc-time-picker-input[disabled] {
    color: #333;
    background: #eee;
}

.time-left {
    padding-right: 2px;
}

.single-form-padding {
    padding-right: 15px;
}

.time-right {
    padding-left: 2px;
}

.tickBox {
    display: inline;
    text-align: left;
    margin-bottom: 2px;
}

.checkBox-row {
    padding: 10px 0 10px 11%;
}

.checkbox-bottom-spacing {
    margin-bottom: 40px;
}

input[type="checkbox"] {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    min-width: 15px;
    height: 15px;
    margin: -1px 0 0;
}

#upload-another-event {
    width: 12px;
    height: 12px;
}

.tool-tip {
    margin: 0;
    left: -5%;
}

.settings-tool-tip {
    float: right;
    height: 20px;
    width: 20px;
    background-image: url("assets/images/form-tooltip-grey.png");
    text-decoration-color: rgb(158, 158, 158);
    color: rgb(158, 158, 158);
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
    z-index: -1;
}

.tool-span {
    visibility: hidden;
    position: absolute;
    height: 50px;
    width: 200px;
    font-size: 0;
    box-sizing: border-box;
    padding: 7px;
    bottom: -46px;
    right: 50px;
    top: -4px;
    left: -185px;
    transition: left 0.5s;
    overflow: hidden;
}

.upload-internal-buffer-slider {
    background: #e6e6e6;
}

.upload-internal-buffer-slider .rangeslider__fill {
    background-color: #7cb342 !important;
    height: 100%;
    border-radius: 10px;
    border-bottom: 10px solid #7cb342;
}

.settings-tool-tip:hover .tool-span {
    visibility: visible;
    font-size: 11px;
    font-family: "Nunito Sans", sans-serif;
    color: white;
    background-color: rgb(158, 158, 158);
    opacity: 1;
    overflow: none;
    line-height: 12px;
    left: -160px;
}

.submitButton,
.restartDeviceButton {
    float: right;
    height: 35px;
    width: 90px;
    border-radius: 30px;
    margin-right: 16px;
    border: 1px solid silver !important;
    background: gold;
    color: white;
    font-size: 13px;
    padding-top: 7px;
}

.restartDeviceButton {
    width: 140px;
    background: rgb(17, 177, 44) !important;
    font-weight: bold;
}

.submitButton:hover,
.restartDeviceButton:hover {
    border: 1px solid silver !important;
    background: gold;
    color: white;
}

.submitButton:focus,
.submitButton:active:hover,
.restartDeviceButton:focus,
.restartDeviceButton:active:hover {
    border: 1px solid silver !important;
    background: gold;
    color: white;
    outline: none;
}

.sectionTwo {
    margin-top: 2px;
    background-color: white;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 700px;
    z-index: 1;
}

.optionalStatement {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    margin-left: 20px;
    text-align: left;
}

.vs-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    display: block;
    margin-bottom: 3px;
    margin-top: -4px;
    padding-right: 3px;
}

#description-field {
    width: 100%;
    height: 110px;
    border-radius: 5px;
    border: 1px solid gray;
    margin-top: -2px;
}

.sectionThree {
    background-color: white;
    width: 25%;
    display: inline-block;
    float: left;
    padding: 20px;
    padding-bottom: 10px;
    z-index: 1;
    font-family: "Nunito Sans", sans-serif;
}

.bottomSectionTitle {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.bottomSectionText {
    text-align: left;
    font-size: 13px;
}

.sectionFour {
    background-color: white;
    display: inline-block;
    float: right;
    width: 74%;
    padding: 20px;
    padding-bottom: 35px;
    z-index: 1;
}

.upload-thumbnail-button-wrap {
    padding: 10px 15px;
}

.image-tile {
    padding-top: 20px;
    display: inline-block !important;
    text-align: center;
}

.thumbnail-stock-image-selector {
    height: 115px;
    margin: 0 50px;
}

.slick-dots li button {
    width: 5px;
    height: 5px;
    font-size: 0;
    border: none;
    border-radius: 7px;
    background: rgb(211, 210, 210);
    padding: 0;
}

.slick-dots li {
    width: 5%;
    display: inline-block;
}

.slick-dots .slick-active button {
    background: grey;
}

.slick-dots {
    width: auto;
    text-align: center;
    display: inline-block;
    padding: 0;
}

.slick-prev {
    left: -25px;
}

.slick-next {
    right: -25px;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 75%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev::before {
    content: "←";
}

.slick-next::before {
    content: "→";
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
}

#upload-error {
    float: right;
    padding-right: 4%;
    margin-top: 10px;
}

.end-time-tick-box {
    position: absolute;
    top: 30%;
    display: inline;
    text-align: left;
}

.upload-page-save-cancel-button-panel {
    padding-right: 30px;
    margin-top: 40px;
}

#latency-input::-webkit-inner-spin-button,
#latency-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.upload-page-checkbox-label-padding {
    padding-left: 5px;
}

#device-internal-buffer-length-label {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
}

.device-ingest-internal-buffer-length-container {
    width: 300px;
}

.upload-event-attr-input {
    display: inline-block;
}

.upload-event-attr-label {
    display: inline-block;
    width: 37%;
}

.upload-srt-fields {
    background-color: rgba(239, 239, 239, 0.3);
}

@media screen and (max-width: 915px) and (min-width: 768px) {
    .endtime-checkbox-padding {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .upload-flex-row-center {
        display: block;
    }

    .checkbox-commentator-spacing {
        margin: auto;
        max-width: 400px;
        padding-left: 7px;
        width: 100%;
    }

    .upload-page-left-margin {
        margin-left: 8.333%;
    }

    .upload-forms-container {
        border-bottom: 1px solid rgba(50, 50, 49, 0.05);
        padding-bottom: 5px;
    }

    #upcomingEventsComponent {
        width: 100%;
        float: unset;
        padding: 10px 0px;
    }

    #upload-error {
        padding-left: 50%;
    }

    .center-on-small-screen {
        width: 100%;
        max-width: 400px;
        margin: auto;
    }

    .sectionOne {
        min-height: 830px;
    }

    .time-left {
        padding-left: 0;
    }

    .single-form-padding {
        padding-right: 0;
    }

    .time-right {
        padding-right: 0;
    }

    .tickBox {
        padding-left: 0;
        left: 0 !important;
        padding-bottom: 5px;
    }

    #tickboxGameSound {
        padding-bottom: 12px;
    }

    .tool-tip {
        left: 0;
    }

    .iconWrapper {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .submitButton {
        margin-right: 0;
    }

    .sectionTwo {
        margin-bottom: 10px;
    }

    .sectionThree {
        width: 100%;
        margin-bottom: 10px;
    }

    .sectionFour {
        width: 100%;
    }

    .sportSection,
    .timeSection {
        margin-left: 8.3%;
    }

    .image-tile {
        padding-left: 5px;
        padding-right: 5px;
    }

    .slick-prev,
    .slick-next {
        top: 95%;
    }

    .slick-prev {
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }

    .slick-prev:before,
    .slick-next:before {
        color: black;
    }
}

@media screen and (max-width: 575px) {
    .checkbox-commentator-spacing {
        padding-left: 0px;
    }

    #upload-error-div {
        text-align: center;
    }

    #upload-error {
        float: none;
        padding: 0;
    }

    .sectionOne {
        padding-left: 15px;
    }

    .upload-section {
        width: 90%;
        height: auto;
    }

    .upload-link {
        width: 80%;
    }

    .sectionTwo {
        min-height: 520px !important;
    }

    .submitButton {
        margin: 0;
        margin-top: 8px;
    }

    .submitButton {
        float: none;
    }

    .bottom-cancel {
        margin-left: 0;
    }

    .start-time {
        padding-top: 30px !important;
    }

    .end-time {
        padding-top: 45px !important;
    }

    #tickboxUploadMore {
        min-width: 100% !important;
        text-align: left !important;
        margin-right: 6px !important;
    }
}

growl-notifications {
    position: fixed;
    top: 60px;
    right: 15px;
    z-index: 1000;
}

:global(growl-notifications) :global(growl-notification) {
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 15px 30px;
    width: 250px;
    display: block;
    border-radius: 5px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.8);
}

:global(growl-notifications) :global(growl-notification) :global(.close) {
    color: #fff;
    font-size: 16px;
    margin-right: -10px;
    margin-top: 2px;
}

:global(growl-notifications) :global(growl-notification):global(.growl-primary) {
    color: #fff;
    background: #f7a22f;
    border: 1px solid #2698dd;
}

:global(growl-notifications) :global(growl-notification):global(.growl-error) {
    color: #fff;
    background: #e7908e;
    border: 1px solid #da524f;
}

:global(growl-notifications) :global(growl-notification):global(.growl-info) {
    color: #fff;
    background: #85d0e7;
    border: 1px solid #46b8da;
}

:global(growl-notifications) :global(growl-notification):global(.growl-warning) {
    color: #fff;
    background: #f4c37d;
    border: 1px solid #46b8da;
}

.radio-button-label {
    padding-left: 10px;
    padding-right: 10px;
}

.upload-srt-radio-buttons {
    margin-left: 25px;
}

/* dont remove - fixes font weight for option boxes */
.react-datepicker {
    font-weight: 400;
}
