.new-dashboard-body {
    width: 95%;
    min-width: 1293px;
    margin: auto;
}

.table-wrapper {
    background-color: white;
}

.dashboard-react-table .rt-table {
    overflow: unset !important;
}

.new-dashboard-title {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    color: #323231;
    margin-bottom: 35px;
}

.dash-event-info {
    display: inline-block;
    width: 50%;
    padding-left: 10px;
    min-height: 113px;
    position: relative;
}

.new-dash-event-info-bottom {
    height: 100%;
    padding-top: 20px;
    padding-left: 10px;
    text-align: center;
}

#event-link-soundcheck,
#event-link-monitor-webrtc {
    display: inline-block;
    outline: none !important;
    margin: 0 5%;
}

.conflicting-persistent-input-schedules-ett .tooltip-inner,
.conflicting-entitlement-arn-schedules-ett .tooltip-inner,
.conflicting-device-input-schedules-ett .tooltip-inner {
    max-width: 500px !important;
}

#event-link-soundcheck {
    width: 30px;
}

#event-link-monitor-webrtc {
    width: 40px;
}

.new-dash-event-link-img {
    width: 100%;
}

.event-info-img {
    width: 30%;
    display: inline-block;
    height: 78px;
    margin-top: 3px;
}

.dash-menu-img-wrapper {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    vertical-align: middle;
    position: relative;
}

.dash-menu-img {
    height: 45px;
    margin-top: 20px;
    cursor: pointer;
}

.event-info-container {
    cursor: pointer;
    position: relative;
}

#dashboard-wrapper > .ReactTable .rt-thead .rt-resizable-header-content {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    font-size: 17px !important;
    font-weight: bold !important;
    color: #323231 !important;
}

#dashboard-wrapper > .ReactTable .rt-thead.-header {
    border-bottom: 6px solid #fbac2b !important;
    min-width: unset !important;
}

.dashboard-react-table .rt-thead .rt-th:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 0 !important;
    flex: unset !important;
}

#dashboard-wrapper > .ReactTable .rt-thead .rt-tr .rt-th {
    padding: 0 !important;
}

#dashboard-wrapper > .ReactTable .rt-thead .rt-resizable-header {
    padding: 0 !important;
}

#dashboard-wrapper > .ReactTable .rt-thead .rt-header-padding {
    padding: 5px !important;
}

#dashboard-wrapper .ReactTable .rt-tbody {
    overflow: unset !important;
    min-width: unset !important;
}

.custom-dash-header {
    height: 60px;
    background-color: #555555;
    text-align: right;
    position: relative;
}

.custom-sort-by-wrapper {
    width: 113px;
    height: 34px;
    display: inline-block;
    position: relative;
    border-radius: 14.5px;
    background-color: #959595 !important;
    border: none !important;
    text-align: left;
}

.custom-toggle-supported {
    display: inline-block;
    margin-right: 30px;
    color: white;
}

#sort-by-btn {
    padding-top: 7px;
    padding-left: 15px;
    cursor: pointer;
    height: 100%;
}

.triggered-true {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-sort-by {
    color: white;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    font-size: 13.5px;
    font-weight: 600;
}

.event-type {
    color: white;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    font-size: 17.5px;
    font-weight: 600;
    margin-left: -25px;
}

.custom-sort-by-arrow {
    position: absolute;
    right: 21px;
    top: 10px;
    font-weight: 100;
    color: white;
}

.sortby-dropdown {
    background-color: #959595 !important;
    border-bottom-left-radius: 14.5px;
    border-bottom-right-radius: 14.5px;
    width: 113px !important;
    border: none !important;
    box-shadow: none !important;
    text-align: left !important;
    padding: 0 !important;
}

.sort-by-option {
    height: 25px;
    display: block;
    padding-left: 10px;
    cursor: pointer;
    color: white;
}

.sort-by-true {
    color: #323231;
}

.sort-by-option:hover {
    color: #323231;
}

.event-type-dropdown {
    background-color: #fbac2b !important;
    border-bottom-left-radius: 14.5px;
    border-bottom-right-radius: 14.5px;
    width: 195px !important;
    border: none !important;
    box-shadow: none !important;
    text-align: left !important;
    padding: 0 !important;
}

.event-type-option {
    height: 35px;
    display: block;
    padding-left: 10px;
    cursor: pointer;
    color: white;
}

.event-type-selector {
    width: 195px;
    height: 42px;
    border-radius: 14.5px;
    background-color: #fbac2b;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
}

.event-type-triggered-true {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.default-comm-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    display: block;
}

#event-type-btn {
    height: 42px;
    margin-top: -8px;
    padding-top: 8px;
}

.info-dropdown-image-change-btn {
    position: absolute;
    width: 236px;
    height: 36px;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.48);
    color: white;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    border: none;
    z-index: 10;
    left: calc((100% - 236px) / 2);
    top: calc((100% - 36px) / 2);
    outline: none !important;
}

.grey-btn {
    width: auto;
    height: 36px;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.48);
    color: white;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    border: none;
    z-index: 10;
    outline: none !important;
}

.info-dropdown-label {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 13.5px;
    font-weight: bold;
}

.info-dropdown-input {
    height: 51px;
    width: 100%;
    border: 1px solid #b4b4bb;
    border-radius: 4px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #323231;
    padding: 15px;
    letter-spacing: 0.7px;
}

.info-set-time {
    padding-left: 5%;
    padding-right: 5%;
}

.info-dropdown-input > .rc-time-picker-input {
    font-size: 17px;
    outline: none !important;
    text-align: center !important;
}

.info-dropdown-title {
    padding: 0 20px;
    text-overflow: ellipsis;
}

.info-dropdown-description {
    height: 190px;
    resize: none;
    overflow-y: auto !important;
    line-height: 1;
}

.info-dropdown-description::-webkit-scrollbar,
.info-drop-down::-webkit-scrollbar {
    width: 5px;
}

.info-dropdown-description::-webkit-scrollbar-thumb,
.info-drop-down::-webkit-scrollbar-thumb {
    background: lightgray;
}

.info-dropdown-sport {
    width: 100%;
    height: 51px;
    background-color: white;
    appearance: none;
    outline: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15% !important;
    white-space: nowrap;
}

.info-dropdown-glyph {
    position: absolute;
    right: 9%;
    top: 20px;
    cursor: pointer;
}

.table-wrapper > .ReactTable .rt-tbody .rt-expandable {
    width: 0 !important;
    max-width: 0 !important;
    padding: 0 !important;
}

#live-soon-table > .ReactTable .rt-thead.-header {
    height: 0 !important;
    border: none !important;
    overflow: hidden !important;
}

.info-privacy-option {
    padding: 10px;
}

.info-privacy-option:hover {
    background-color: rgba(85, 85, 85, 0.3);
}

.blue-btn {
    margin: 0 20px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 17.5px;
    font-weight: bold;
    color: white !important;
    background: linear-gradient(to right, #0691ee, #43aef0);
    border: 3px solid #4494de;
    outline: none !important;
    border-radius: 30px;
    width: auto;
    min-width: 118px;
    height: 37px;
    padding: 0 10px;
}

#delete-event-btn,
.red-btn {
    background: linear-gradient(to right, #ee0606, #f05743);
    border: 3px solid #de4444;
}

.blue-btn-info {
    margin: 0;
    margin-left: 2%;
    min-width: 101px;
    height: 32px;
}

.blue-btn:hover,
.blue-btn:focus {
    border: 3px solid #2181db;
}

.rtmp-dropdown-not-live-container {
    padding: 20px;
    text-align: center;
}

.rtmp-dropdown-not-live-message {
    font-size: 17px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.align-right {
    text-align: right;
}

.share-custom-width {
    width: 70%;
    display: inline-block;
}

.team-name-wrapper {
    display: inline-block;
    width: calc(30% - 80px);
    margin-top: 20px;
    height: 47px;
    vertical-align: top;
}

.rtmp-checkbox-wrapper {
    padding-top: 24px;
    padding-bottom: 20px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.status-indicator-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    bottom: 1px;
    right: 1px;
}

.poll-failed-img {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: -1px;
    right: 1px;
}

.width-70 {
    width: 70%;
}

.info-drop-down {
    width: calc(100% - 20px) !important;
    max-height: 240px;
    overflow-y: auto;
}

.events-unsettable-time {
    display: block;
    height: 51px;
    padding: 16px;
    padding-left: 0;
    font-size: 17px;
}

.info-dropdown-tooltip {
    display: inline-block;
    height: 17px;
    width: 17px;
    margin-left: 10px;
}

.sport-dropdown-container {
    padding-right: 20px;
    margin-bottom: 10px;
    position: relative;
}

#info-dropdown-sport-trigger {
    position: relative;
}

#sport-drop-down,
#comm-drop-down {
    padding: 5px;
}

.set-time-input {
    width: 50%;
    display: inline-block;
    padding: 5px;
}

#info-dropdown-comm-trigger {
    position: relative;
    margin-bottom: 15px;
}

.live-soon-text {
    display: block;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.live-soon-counter {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif !important;
    font-size: 24px !important;
    font-weight: bold !important;
}

.no-comms-container {
    text-align: center;
    margin: 10px;
}

.padding-5px {
    padding: 5px;
}

.no-cursor-pointer {
    cursor: unset;
}

.remove-padding {
    padding: 0px !important;
}

.dashboard-update-time-block {
    background-color: #555;
    color: white;
    font-weight: 100;
    padding: 2px;
    font-size: 10px;
    margin-top: 5px;
}

.dashboard-dropdown-selected {
    background-color: #ddd;
}

.dashboard-updated-at-info {
    color: white;
    font-weight: 100;
}

.dashboard-error-status-info {
    padding-right: 10px;
    color: red;
    font-weight: 100;
}

@media screen and (max-width: 1150px) {
    .default-comm-name {
        max-width: 140px;
    }

    .dashboard-update-time-block {
        font-size: 0.8vw;
    }

    .dashboard-updated-at-info {
        font-size: 0.9vw;
        top: 2.9vh;
    }

    .dashboard-error-status-info {
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 1024px) {
    .event-info-img {
        min-height: 60px;
    }

    .info-dropdown-input {
        height: 45px;
    }

    .info-dropdown-input,
    .info-dropdown-input > .rc-time-picker-input {
        font-size: 14px;
        padding: 12px;
    }

    .blue-btn-info {
        font-size: 14.5px;
        min-width: 80px;
    }

    .info-set-time {
        padding: 0;
    }

    .info-dropdown-glyph {
        top: 16px;
    }

    .info-dropdown-description {
        height: 154px;
    }

    .info-dropdown-image-change-btn {
        width: 190px;
        left: calc((100% - 190px) / 2);
    }

    .status-indicator-dot {
        width: 12px;
        height: 12px;
        right: 31px;
    }

    .poll-failed-img {
        width: 20px;
        height: 20px;
        right: 26px;
    }
}
