#player-container {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #9e9e9e;
    padding-right: 0;
}

.optimised-player-container {
    color: white;
}

.upload-live-event {
    padding: 0 20px 20px 0;
    z-index: 1;
}

#player-container > .video-react {
    outline: none !important;
}

.middle-wrap {
    width: 100%;
    max-width: 980px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 100px;
}

.no-underline-hover:hover {
    text-decoration: none;
}

.no-underline-hover:hover {
    text-decoration: none;
}

.icon-title-spacing {
    margin-right: 5px;
}

.force-hide-control-bar {
    visibility: hidden !important;
}

.main-wrap {
    margin: 0 auto;
    padding-bottom: 20px;
    box-sizing: border-box;
    height: 100%;
    float: left;
    width: 100%;
    padding: 0;
}

.reload-error-message {
    width: 100%;
    display: inline-block;
}
#main-content {
    float: left;
    width: 100%;
    padding: 0;
}

.count-font {
    font-family: Arial;
}

.commentator-studio-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 20px 20px 10px 20px;
}

.commentator-studio-container {
    margin: 10px auto;
    overflow: auto;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.upload-title {
    margin-bottom: 10px;
    display: inline-block;
    color: #333;
}

#invite-text-field {
    width: 50%;
    margin-left: 5px;
    display: inline-block;
}

.spalk-input {
    display: block;
    height: 40px;
    border: 1px solid gray;
    border-radius: 4px;
    padding-left: 10px;
    color: gray;
}

.commentator-studio-td-padding {
    padding: 0;
}

.commentator-studio-table-spacing {
    border-spacing: 0;
}

.invite-link-button {
    display: inline-block;
    border: 1px solid #f7a22f;
    border-radius: 18px;
    height: 35px;
    width: auto;
    background-color: #fff;
    color: #f7a22f;
    float: none;
    margin-top: inherit;
    padding: 10px;
    margin-left: 5px;
}

.invite-link-button:hover {
    background-color: #f7a22f;
    color: #fff;
}

#main-content.main-cont-owner {
    background-size: cover;
    background-attachment: fixed;
}
#main-content {
    height: 100%;
    padding-top: 60px;
}
#main-content,
.main-wrap {
    float: left;
    width: 100%;
    padding: 0;
}
#main-content {
    background-size: cover;
    background-attachment: fixed;
    margin-bottom: -1px;
    position: relative;
    top: 60px;
}

#commentary-team-panel {
    margin-top: 5px;
}

#commentary-team-panel,
#commentator-share-panel {
    width: inherit;
    padding-right: 0;
    margin-right: 10px;
}

.commentator-studio-title {
    margin-bottom: 10px;
    display: inline-block;
    color: #333;
}

#cmtr-status-box {
    float: right;
    margin-right: 20px;
    border: 1px solid #333;
    padding: 10px;
    margin-bottom: 1px;
    margin-top: 0px;
}

.redBackground {
    background-color: red;
}

.commentator-studio-general-style {
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
    text-decoration: none;
    line-height: 100%;
    text-align: left;
}

.commentator-tile-padding {
    padding: 20px;
}

/* 
 * ----------------------------------------------------------------------------------------------------------
 * Custom CSS for video-react components
 * ----------------------------------------------------------------------------------------------------------
 */

.video-react-fullscreen-control.video-react-fullscreen-control-right {
    position: absolute;
    right: 0;
}

#player-container .video-react-icon-fullscreen-exit {
    font-size: 17px;
    bottom: 47px;
    right: 12px;
}

/*
 * ----------------------------------------------------------------------------------------------------------
 */

.game-info-container {
    word-break: break-word;
}

.upload-live-stats.broadcaster {
    margin-right: 8.5px;
    margin-top: 8px;
}

.upload-live-stats {
    z-index: 5;
}

@media (max-width: 768px) {
    .upload-live-stats {
        margin-right: 0;
    }
}

.upload-live-stats {
    background-color: rgba(0, 0, 0, 0.7);
    color: gold;
    float: right;
    position: relative;
    display: block;
    padding: 3px 8px;
    height: 35px;
    margin-bottom: -35px;
    margin-right: 25%;
    z-index: 1;
}

.upload-live-stats p,
.upload-live-stats span {
    margin: auto;
    text-align: center;
    width: 100%;
    padding: 0 4px;
}

.upload-live-stats-time {
    position: relative;
    font-size: 12px;
    padding-right: 10px;
    padding-left: 4px;
}

.upload-live-stats p {
    font-size: 9px;
}

#commentary-team-panel,
#commentator-share-panel {
    width: inherit;
    padding-right: 0;
    margin-right: 10px;
}

.share-box-icons {
    margin-top: 20px;
    height: auto;
}

.invite-link-button:focus,
.invite-link-button:active {
    outline: none;
    box-shadow: none;
}

.pointer-hover {
    cursor: pointer;
}

#cmtr-copy-url {
    border: 1px solid #9e9e9e;
    width: 60%;
}

.youtube-account-tabs-active {
    color: #fff;
    background-color: #337ab7;
    width: 100%;
    left: 0px;
}

.youtube-account-tabs-active:hover {
    color: #fff;
    background-color: #337ab7;
    width: 100%;
    left: 0px;
}

.youtube-account-tabs {
    width: 100%;
    left: 0px;
}

.yt-account-container {
    margin: 0px;
    padding-left: 0px;
}
.social-links,
.fb-link,
.tw-link,
.social-links {
    width: 30px;
    height: 30px;
}

.social-links a {
    width: 28px;
    height: 28px;
    margin: 0 5px;
}

.social-media-share-link {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
    padding-right: 0;
}

.social-links > * {
    width: 20%;
    margin: auto;
    display: inline-block;
}

.selectable-text {
    cursor: pointer;
    color: #9e9e9e;
}

.red-bg {
    background-color: red;
}

.social-media-link-icons-shrink {
    display: inline-block;
    justify-content: none;
}

.grid-layout {
    width: 100%;
}

.share-input-form {
    vertical-align: text-bottom;
}

.fb-and-twitter-icon-styling {
    vertical-align: top;
    width: 40%;
}

.fb-and-yt-live-icon-styling {
    width: 40%;
    margin-top: 5px;
}

.social-media-share-link {
    width: 50%;
}

.fb-share-button {
    padding-left: 0;
}

.tw-share-button {
    margin-right: 0;
}

.controls-container {
    display: flex;
    width: 100%;
    margin-top: 7px;
    gap: 7px;
}

.controls-container.fullscreen-controls {
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color: rgba(32, 32, 32, 0.7);
}

.commentary-control-container {
    display: flex;
    background: #323231;
    padding: 0px 20px;
    width: 100%;
    position: relative;
}

.commentary-control-container.fullscreen-controls {
    height: 100px;
    background: transparent;
    padding: 0;
    justify-content: center;
    width: auto;
    flex: 0 0 auto;
}

.utility-button-container {
    text-align: center;
    padding: 15px;
    flex: 1 0 auto;
}

.utility-button-image {
    width: 60px;
    cursor: pointer;
}

.video-react .video-react-video {
    background-color: black !important;
}

.video-react .video-react-fullscreen-control {
    position: absolute;
    right: -15px;
    height: 30px;
    margin-top: -55px;
    font-size: 20px;
}
.video-react .Toastify__toast-body {
    font-size: 14px !important;
}

.video-warning-msg,
.audio-warning-msg {
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    animation: pulse 2s infinite;
    text-align: center;
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 28px;
    padding: 25px;
    border-radius: 10px;
    line-height: 1;
    user-select: none;
}

.video-warning-msg {
    top: 40%;
}

.video-warning-msg-fullscreen,
.audio-warning-msg-fullscreen {
    font-size: 55px;
}

/* Create a class that applies the animation */
.pulsing-bg {
    background-color: rgba(239, 68, 68, 0.9); /* initial red background */
    animation: pulse-red 2s infinite ease-in-out; /* apply the animation */
}

/* Define the keyframes animation */
@keyframes pulse-red {
    0% {
        background-color: rgba(239, 68, 68, 0.9); /* vibrant red with high opacity */
    }
    50% {
        background-color: rgba(239, 68, 68, 0.5); /* medium red opacity */
    }
    100% {
        background-color: rgba(239, 68, 68, 0.9); /* back to vibrant red */
    }
}

.audio-warning-dismissed {
    display: none;
}

.multiple-audio-tracks-select {
    position: absolute !important;
    /* `right` is set by tailwind CSS */
    bottom: 17px;
    width: 92px !important;
    outline: none !important;
    z-index: 50;
    height: 20px;
    text-align: center;
}

.multiple-audio-tracks-select__control {
    height: 20px;
    border-top: 1px lightgrey solid !important;
}

.multiple-audio-tracks-select,
.multiple-audio-tracks-select__single-value,
.multiple-audio-tracks-select__control,
.multiple-audio-tracks-select__control:focus,
.multiple-audio-tracks-select__value-container,
.multiple-audio-tracks-select__option,
.multiple-audio-tracks-select__menu,
.multiple-audio-tracks-select__option--is-selected,
.multiple-audio-tracks-select__option--is-focused,
.multiple-audio-tracks-select__control {
    outline: none !important;
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    color: black;
    font-size: 12px;
    cursor: pointer !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 20px !important;
    margin-bottom: 0px !important;
    margin-top: 0 !important;
    background-color: whitesmoke !important;
}

.multiple-audio-tracks-select__single-value {
    padding-top: 2px !important;
}

.multiple-audio-tracks-select__option--is-selected,
.multiple-audio-tracks-select__option--is-focused,
.multiple-audio-tracks-select__option--is-selected:hover,
.multiple-audio-tracks-select__option {
    cursor: pointer !important;
    padding: 4px !important;
    height: 20px;
}

.multiple-audio-tracks-select__option,
.multiple-audio-tracks-select__option:hover,
.multiple-audio-tracks-select__option--is-focused,
.multiple-audio-tracks-select__option--is-focused:hover {
    background-color: white !important;
    cursor: pointer !important;
    height: 20px;
}

.multiple-audio-tracks-select .css-205izw {
    box-shadow: none !important;
}

.multiple-audio-tracks-select__option--is-selected,
.multiple-audio-tracks-select__option--is-selected:hover {
    background-color: #9e9e9e !important;
}

.multiple-audio-tracks-select .css-1g6gooi {
    color: transparent !important;
}

.multiple-audio-tracks-select__menu-list {
    padding: 0 !important;
    overflow-y: unset !important;
}

.video-react-fullscreen .multiple-audio-tracks-select {
    right: 100px !important;
    bottom: 32px;
    height: 38px;
}

.video-react-fullscreen .multiple-audio-tracks-select__value-container,
.video-react-fullscreen .multiple-audio-tracks-select__single-value,
.video-react-fullscreen .multiple-audio-tracks-select__control,
.video-react-fullscreen .multiple-audio-tracks-select .css-2o5izw,
.video-react-fullscreen .multiple-audio-tracks-select__option,
.video-react-fullscreen .multiple-audio-tracks-select__option--is-selected,
.video-react-fullscreen .multiple-audio-tracks-select__option--is-focused,
.video-react-fullscreen .multiple-audio-tracks-select__control {
    min-height: 38px !important;
    height: 38px !important;
}

.video-react-fullscreen .multiple-audio-tracks-select__single-value {
    padding-top: 13px !important;
}

.video-react-fullscreen .multiple-audio-tracks-select__option--is-selected,
.video-react-fullscreen .multiple-audio-tracks-select__option--is-focused,
.video-react-fullscreen .multiple-audio-tracks-select__option {
    padding: 12px 8px !important;
}

#scanner-canvas {
    height: 100%;
    display: inline-block;
    width: 400px;
    height: 42px;
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 50;
}

@media (min-width: 640px) {
    #scanner-canvas {
        top: 5rem;
    }
}

.bpb-webrtc-show {
    display: unset !important;
}

@media (max-width: 450px) {
    .commentator-studio-container {
        padding: 0;
    }
}

@media (max-width: 380px) {
    .fb-share-button {
        padding-left: 20px;
    }
    #commentator-share-panel {
        display: contents;
        width: inherit;
        padding-right: 0;
        margin-right: 10px;
    }

    .social-links > * {
        margin: auto;
        display: block;
    }

    .commentator-studio-item {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 20px 20px 10px 20px;
        width: 100%;
        display: inline-table;
        margin-left: 0px;
    }

    .col-sm-9 {
        padding-right: 0;
        padding-left: 0;
    }

    .social-media-link-icons-shrink {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .social-media-share-link {
        margin-right: 17vw;
        width: auto;
    }

    #cmtr-copy-url {
        margin-top: 15px;
    }

    .fbLiveButton {
        width: auto;
        margin-right: 20px;
    }

    .ytLiveButton {
        width: auto;
    }

    .grid-layout {
        width: 100%;
    }

    .share-input-form {
        vertical-align: auto;
        padding-top: 0;
        margin-left: 32vw;
    }
    .fb-and-twitter-icon-styling {
        vertical-align: auto;
        width: auto;
    }

    .tw-share-button {
        margin-right: 20vw;
    }

    .fb-and-yt-live-icon-styling {
        margin-left: 20vw;
        margin-top: 3vh;
    }

    .upload-live-stats.broadcaster {
        margin-right: 8px;
    }
}

.system-setting-input-wrap {
    display: flex;
    align-items: center;
}

.system-setting-checkbox {
    margin: 0;
}

.system-setting-label {
    font-family: "AvenirNext", Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 0;
}

.force-reconnect-to-twilio-img {
    position: absolute;
    height: 35px;
    width: 35px;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
}

.force-twilio-in-cms {
    position: relative;
    bottom: -10px;
    right: 8px;
    height: 37px;
    width: 37px;
}

.fullscreen-controls .force-reconnect-to-twilio-img {
    bottom: 10px;
}

@media (max-width: 950px) {
    .force-reconnect-to-twilio-img {
        right: 25px;
    }
}

@media (max-width: 768px) {
    .force-reconnect-to-twilio-img {
        right: 20px;
    }
}
