/**
* By default, CodeMirror's editor has `height: auto`, which means it will adjust
* its height to fit the amount of lines it has.
* We change this to make the editor get its height from its parent element.
* https://codemirror.net/examples/styling/
*/
.cm-editor {
    height: 100%;
}

/**
* Make CodeMirror's editor parent be vertically resizeable with initial height of 200px. 
* Vertical resizing can only be between 100px and 600px.
* 
* I couldn't get this behaviour to work in Tailwind CSS, so it is in this CSS file.
*/
.spalk-cm-editor-container {
    resize: vertical;
    overflow-y: auto;
    height: 200px;
    min-height: 100px;
    max-height: 600px;
}
