.commentator-studio-chat-container {
    background-color: #323231;
    padding: 5px;
    flex: 1 1 calc(50% - 14px);
    height: 350px;
    display: flex;
    flex-direction: column;
}

.commentator-studio-container .commentator-studio-chat-container {
    min-width: 475px;
}

.commentator-studio-chat-container.fullscreen-controls {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 270px);
    background-color: rgba(32, 32, 32, 0.7);
    width: 475px;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease-in-out;
}

.commentator-studio-chat-container.fullscreen-controls.chat-hidden {
    transform: translate(475px, 0);
}

.commentator-studio-chat-container .chat-controls {
    border: 1px solid #959595;
    height: 60px;
}

.commentator-studio-chat-container.fullscreen-controls .chat-controls {
    border: none;
}

.type-switch-container {
    position: relative;
}

.commentator-studio-chat-container .chat-controls .type-switch-container {
    display: inline-block;
    margin: 4px;
    height: 50px;
}

.commentator-studio-chat-container.fullscreen-controls .chat-controls .type-switch-container {
    display: none;
}

.type-switch {
    display: inline-block;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 5px;
    border: none;
    background: none;
    outline: none;
}

.commentator-studio-chat-container.fullscreen-controls .chat-controls .type-switch-container .type-switch {
    display: none;
}

.chat-content {
    font-family: "Nunito Sans", sans-serif;
}

.commentator-studio-chat-container .chat-content {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #959595;
    padding: 10px;
    height: 280px;
    position: relative;
}
.commentator-studio-container .commentator-studio-chat-container .chat-controls,
.commentator-studio-container .commentator-studio-chat-container .chat-content {
    min-width: 455px;
}

.commentator-studio-chat-container.fullscreen-controls .chat-content {
    border: none;
    flex-grow: 1;
    padding: 15px 0;
}

.team-chat-msg-field {
    background-color: transparent;
    border: none;
    outline: none !important;
    color: white;
    width: calc(100% - 65px);
    word-wrap: break-word;
    display: inline-block;
    font-size: 14px;
    resize: none;
    overflow-y: auto;
    vertical-align: top;
    padding: 7px 10px;
}

.team-chat-input-container {
    border: 1px solid #959595;
    background-color: #323231;
    border-radius: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.team-chat-input-container textarea {
    line-height: 15px;
}

.team-chat-time-displayed {
    font-size: 10px;
    color: white;
    display: block;
    position: absolute;
    right: 10px;
    bottom: -5px;
}

.all-messages-container {
    height: 210px;
    overflow: hidden;
    overflow-y: auto;
}

/* chose "@media (min-width: 1024px)" to match tailwind's "lg" responsive design*/
@media (min-width: 1024px) {
    .all-messages-container {
        height: 190px;
    }
}

.commentator-studio-chat-container.fullscreen-controls .all-messages-container {
    height: calc(100% - 60px);
}

.all-messages-container::-webkit-scrollbar,
.team-chat-msg-field::-webkit-scrollbar {
    width: 5px;
}

.all-messages-container::-webkit-scrollbar-thumb,
.team-chat-msg-field::-webkit-scrollbar-thumb {
    background: rgba(235, 233, 233, 0.842);
}

.studio-chat-refresh {
    color: #f9a01e;
    font-size: 20px;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

#fullscreen-chat-toggle {
    position: relative;
    display: none;
}

#fullscreen-chat-toggle.fullscreen-controls {
    display: block;
    position: absolute;
    background-image: linear-gradient(to right, rgba(32, 32, 32, 0.7) 70px, 70px, transparent 100%);
    border-radius: 50%;
    height: 195px;
    width: 195px;
    right: 350px;
    top: calc(50% - 329px);
    padding: 80px 30px;
    font-size: 35px;
    font-family: sans-serif;
    transition: 0.5s ease-in-out;
}

#fullscreen-chat-toggle.fullscreen-controls.chat-hidden {
    transform: translate(475px, 0);
}

#hide-chat-img {
    width: 35px;
    margin-top: -8px;
}

#fullscreen-chat-toggle .chevron {
    transition: 0.5s ease-in-out;
    width: 27px;
}

#fullscreen-chat-toggle.chat-hidden .chevron {
    transform: scaleX(-1);
}

.optimised-performance-video-styling {
    z-index: 15 !important;
}

.chat-video-room {
    display: contents;
}

#team-member-tracks {
    display: grid;
    grid-gap: 10px;
    max-height: 100%;
}

#fullscreen-video-room-wrapper.fullscreen-controls #team-member-tracks {
    position: relative;
    display: flex;
    margin: 10px;
}

#team-member-tracks.fullscreen-controls {
    flex-wrap: nowrap;
}

#fullscreen-video-room-wrapper.video-hidden {
    display: none !important;
}

#fullscreen-video-room-container {
    display: none;
}

#fullscreen-video-room-container.fullscreen-controls {
    display: block;
    position: absolute;
    bottom: 100px;
    right: 0;
    height: 170px;
    width: 450px;
    background-color: rgba(32, 32, 32, 0.7);
    padding: 5px;
}

#fullscreen-video-room-container.fullscreen-controls.video-hidden,
.chat-video-room.video-hidden {
    display: none;
}

#fullscreen-video-room-container .inner-border {
    border: 1px solid #959595;
    height: 135px;
}

#fullscreen-video-room-container #video-controls {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #959595;
    height: 25px;
}

#fullscreen-video-room-container #video-controls #hide-video {
    float: right;
    font-size: 11px;
    font-family: sans-serif;
    padding: 7px 7px;
}

#fullscreen-video-toggle {
    display: none;
}

#fullscreen-video-toggle.fullscreen-controls.video-hidden {
    display: block;
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 10;
}

#team-member-tracks .video-container {
    position: relative;
    display: flex;
}

#team-member-tracks.fullscreen-controls .video-container {
    width: 168px;
}

#team-member-tracks .video-nametag {
    position: absolute;
    bottom: 7px;
    height: 20px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
}

.new-message-notification-dot {
    background-color: red;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 0;
    left: 35%;
}

.chevron > .new-message-notification-dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: -50px;
    left: 25px;
}

.twilio-error-icon {
    height: 14px;
    width: 20px;
}

.twilio-error-icon.twilio-no-audio {
    padding: 0 5px;
}

.twilio-error-icon.twilio-no-video {
    margin-right: 5px;
}
