.commentator-output-info-container {
    vertical-align: top;
    margin: 0px 5px 0px 10px;
    padding: 20px;
}

.output-scheduling-info-container {
    flex: 1;
    margin-right: 10px;
}

.output-scheduling-container {
    background-color: white;
    margin-bottom: 20px;
}

.commentator-status-info {
    width: 100%;
}

.output-schedule-form {
    margin: 10px 0px;
    width: 50%;
}

input[type="number"].input-without-spinners::-webkit-inner-spin-button,
input[type="number"].input-without-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.white-block {
    box-shadow: 0px 8px 7px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
}

#output-img {
    height: 30px;
    margin-left: 13px;
    margin-right: 9px;
}

.output-img-text {
    font-size: 18px;
    vertical-align: bottom;
    line-height: 1;
}

.output-scheduling-info-container {
    width: 100%;
}

.output-scheduling-options-container {
    width: 100%;
    padding: 20px;
    padding-left: 30px;
    margin-top: 5px;
}

.output-scheduling-options-container-existing-schedule {
    margin-top: 5px;
}

.existing-schedule-label-sched-type,
.existing-schedule-label {
    font-size: 14px;
    padding: 5px;
}

.existing-schedule-target {
    width: 80%;
    display: inline-block;
    border: none;
    background-color: transparent;
}

.existing-schedule-label-sched-type {
    padding-left: 30px;
}

.existing-schedule-label-sched-type-info {
    font-weight: normal;
    font-size: 13px;
}

.existing-schedule-chevron-glyph {
    padding: 10px;
    padding-right: 0;
    cursor: pointer;
}

.commentator-profile-img {
    width: 77px;
    height: 77px;
    border-radius: 50px;
}

.commentator-status-block {
    align-items: center;
}

.commentator-profile-img-container {
    display: inline-block;
    padding: 20px;
}

.output-provider-img {
    width: 150px;
}

.commentator-info {
    padding-left: 20px;
}

.schedule-status-block {
    padding: 20px;
}

.schedule-text-color-blue {
    color: #159df3;
}

.schedule-text-color-green {
    color: #2bd97d;
}

.commentator-dropdown-select {
    width: 100%;
}

.commentator-status-block {
    margin: 10px 0;
}

.schedule-status-text {
    line-height: 26px;
}

.schedule-btns-container {
    text-align: right;
    margin: 10px;
}

.schedule-btn-container {
    display: inline-block;
    margin-right: 5px;
}

.schedule-btn {
    height: 100%;
}

.dashboard-seperator-section-header {
    height: 61px;
    background-color: #555555;
    margin: 10px 0;
    padding: 20px;
    color: white;
}

.dashboard-seperator-social-publish-background {
    background-color: rgba(85, 85, 85, 0.54);
}

.dashboard-seperator-section-text {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    line-height: 1;
    font-size: 13.5px;
    font-weight: 600;
}

.width-50 {
    width: 50%;
}

.custom-share-wrapper {
    width: calc(100% - 100px);
    display: inline-block;
}

.output-scheduling-custom-time-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    width: 50%;
}

.output-schedule-time-picker {
    width: 49%;
}

.output-custom-options-container .form-label {
    display: inline-block;
    margin-right: 10px;
}

.output-adv-opts-select {
    width: 50%;
    display: inline-block;
}

.output-custom-options-row {
    border-top: 1px dotted rgb(231, 230, 230);
    padding: 13px 15px 0 15px;
    height: 65px;
}

.output-custom-options-private,
.output-custom-options-public {
    display: inline-block;
    width: 30%;
}

.output-audio-option {
    width: 40%;
}

.output-custom-options-width-15 {
    width: 15%;
}

.output-custom-options-width-25 {
    width: 25%;
}

#hex-prefix-span-output-options {
    margin-right: 5px;
}

.output-custom-options-public.output-custom-options-checkbox {
    width: 12.5%;
}

.output-custom-options-public .form-label {
    line-height: 26px;
}

.output-frame-rate-option-disabled {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.output-custom-options-private {
    padding-top: 15px;
}

#output-dropdown-live-container {
    position: relative;
}

.css-15k3avv {
    height: 77px !important;
    overflow-y: auto !important;
}

.apply-output-advanced-opts {
    text-align: right;
    padding-bottom: 15px;
}

.override-color-to-red {
    background: linear-gradient(to right, #ec0707, #eb5c38) !important;
    border: 3px solid #ec0707 !important;
}

.output-schedule-type {
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
}

#advanced-output-options-label {
    cursor: pointer;
    color: #159df3;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
}

#output-scheduling-advanced-opts-modal .modal-dialog,
#edit-schedule-modal .modal-dialog,
#schedule-while-live-modal .modal-dialog {
    width: 800px !important;
    min-width: 768px !important;
}

#output-scheduling-advanced-opts-modal .modal-body,
#edit-schedule-modal .modal-body,
#schedule-while-live-modal .modal-body {
    padding: 0 !important;
    max-width: unset !important;
}

.output-adv-opts-tooltip {
    top: 3px;
    left: 4px;
}
