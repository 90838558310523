.comm-icon {
    height: 12px;
    width: 12px;
    margin: 0;
    padding: 0;
}

#link-to-profile {
    color: #9e9e9e;
}

#link-to-profile:hover {
    text-decoration: none;
}

.event-tile {
    background-color: white;
    width: 95%;
    max-width: 310px;
    max-height: 275px;
    box-shadow: 0 5px 14px -5px #323231;
    display: inline-block;
    margin: 0;
}

.empty-tile-div {
    text-align: center;
    padding: 20px;
    position: relative;
}

.empty-tile-img {
    height: 7.5vw;
    max-height: 101px;
    display: block;
    margin: auto;
}

.empty-tile-btn {
    width: 75%;
    height: auto;
    border: 4px solid #323231;
    border-radius: 25px;
    background: linear-gradient(to right, #3b3b3a, #52524f) !important;
    color: white !important;
    outline: none !important;
    font-weight: 600;
    font-size: 13px;
    font-family: "Nunito Sans", sans-serif;
    padding: 6px 0;
}

.empty-tile-btn:hover,
.empty-tile-btn:hover:active {
    border: 4px solid #52524f;
}

.event-tile-content {
    text-align: center;
}

.event-link {
    text-decoration: none !important;
    color: #323231 !important;
}

.event-tile-img-container {
    height: 177px;
}

.event-tile-img {
    height: 100%;
    width: 100%;
}

.event-tile-play {
    position: absolute;
    z-index: 68;
    top: 20%;
    left: 40%;
    width: 20%;
    opacity: 0.68;
}

.event-tile-title-container {
    padding: 10px 15px 0;
    text-align: left;
    height: 50px;
    text-overflow: ellipsis;
}

.event-tile-title {
    font-size: 18px;
    font-weight: bold;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 0.7px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-tile-info-container {
    padding: 5px 15px;
    text-align: left;
    color: #d5d5d5;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.event-tile-info-mobile {
    display: block;
    color: #545252;
    font-size: 10px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.comm-link {
    text-decoration: none !important;
    color: #d5d5d5 !important;
}

#event-tile-info-web-views {
    float: right;
}

.no-thumbnail-url {
    background-color: #d2d2d2;
    height: 100%;
    width: 100%;
    margin: auto;
}

@media screen and (max-width: 1024px) {
    .empty-tile-div {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .empty-tile-img {
        height: 75px;
    }

    .empty-tile-btn {
        width: 80%;
        font-size: 11px;
    }

    .event-tile-img-container {
        height: 140px;
    }

    .event-tile-title-container {
        padding: 10px 10px 0;
    }

    .event-tile-title {
        font-size: 15px;
        line-height: 18px;
    }

    .event-tile-info-container {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 768px) {
    .no-thumbnail-url {
        max-width: 250px;
    }

    .event-tile {
        width: 100%;
        max-width: none;
        box-shadow: none;
    }

    .empty-tile-content {
        width: 70%;
        right: 0;
        display: inline-block;
        padding: 5%;
    }

    .event-link {
        width: 35%;
        min-width: 205px;
        display: inline-block;
        vertical-align: top;
    }

    .event-tile-img-container {
        height: 120px;
    }

    .event-tile-img {
        max-width: 250px;
    }

    .event-tile-play {
        width: 5%;
        min-width: 30px;
        left: 22%;
        top: 32%;
    }

    .event-tile-info-outer {
        display: inline-block;
        width: 50%;
    }

    .event-tile-title-container {
        padding-top: 0;
        height: 77px;
    }

    .event-tile-description {
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.4px;
    }

    .upload-comms-img {
        height: 28px;
        display: inline-block;
        vertical-align: top;
        float: right;
    }
}

@media (max-width: 425px) {
    .event-tile-img-container {
        height: 90px;
    }

    .event-tile-title-container {
        height: 51px;
    }
    .event-tile-title {
        font-size: 13px;
        line-height: 14px;
    }

    .event-tile-info-mobile {
        font-size: 9px;
    }
    .event-link {
        width: 50%;
        min-width: unset;
    }
}
