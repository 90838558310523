.error-msg {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-content: center;
    padding-top: 150px;
    padding-bottom: 550px;
    max-width: 600px;
}

.error-msg-inner {
    width: 100%;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 10px;
}

.message {
    color: #000333;
    margin-left: auto;
    margin-right: auto;
}

.link-to-home {
    color: #9e9e9e;
}

.link-to-home:hover {
    text-decoration: none;
    color: #000333;
    transition: color 0.7s ease;
}

.error-tooltip > .tooltip-inner {
    background-color: red;
    color: black;
}

.error-top-placement > .tooltip-arrow {
    border-top-color: red !important;
}

.error-left-placement > .tooltip-arrow {
    border-left-color: red !important;
}

.error-right-placement > .tooltip-arrow {
    border-right-color: red !important;
}

.error-bottom-placement > .tooltip-arrow {
    border-bottom-color: red !important;
}

.no-arrow > .tooltip-arrow {
    border-color: transparent !important;
}

.info-tooltip-colour-green > .tooltip-inner {
    background-color: #39b54a;
    color: black;
}

.info-tooltip-colour-green-top > .tooltip-arrow {
    border-top-color: #39b54a !important;
}

.info-tooltip-colour-green-left > .tooltip-arrow {
    border-left-color: #39b54a !important;
}

.info-tooltip-colour-green-right > .tooltip-arrow {
    border-right-color: #39b54a !important;
}

.info-tooltip-colour-green-bottom > .tooltip-arrow {
    border-bottom-color: #39b54a !important;
}

.info-tooltip-colour-yellow > .tooltip-inner {
    background-color: yellow;
    color: black;
}

.info-tooltip-colour-yellow-top > .tooltip-arrow {
    border-top-color: yellow !important;
}

.info-tooltip-colour-yellow-left > .tooltip-arrow {
    border-left-color: yellow !important;
}

.info-tooltip-colour-yellow-right > .tooltip-arrow {
    border-right-color: yellow !important;
}

.info-tooltip-colour-yellow-bottom > .tooltip-arrow {
    border-bottom-color: yellow !important;
}

.info-tooltip-colour-orange > .tooltip-inner {
    background-color: orange;
    color: black;
}

.info-tooltip-colour-orange-top > .tooltip-arrow {
    border-top-color: orange !important;
}

.info-tooltip-colour-orange-left > .tooltip-arrow {
    border-left-color: orange !important;
}

.info-tooltip-colour-orange-right > .tooltip-arrow {
    border-right-color: orange !important;
}

.info-tooltip-colour-orange-bottom > .tooltip-arrow {
    border-bottom-color: orange !important;
}

.info-tooltip-colour-red > .tooltip-inner {
    background-color: red;
    color: black;
}

.info-tooltip-colour-red-top > .tooltip-arrow {
    border-top-color: red !important;
}

.info-tooltip-colour-red-left > .tooltip-arrow {
    border-left-color: red !important;
}

.info-tooltip-colour-red-right > .tooltip-arrow {
    border-right-color: red !important;
}

.info-tooltip-colour-red-bottom > .tooltip-arrow {
    border-bottom-color: red !important;
}

#error-tooltip {
    z-index: 1337;
}

@media (max-width: 768px) {
    .box-sizing {
        font-size: 10px;
        line-height: 1.2;
        display: inline-block;
    }
}
